.location-search .ant-form-item {
  display: flex;
}
.location-search .ant-form-item-label {
  padding-right: 10px;
  overflow: inherit;
}
.location-search .ant-form-item-control-wrapper {
  width: 100%;
}
.location-search
  .ant-select:not(.ant-select-open)
  .ant-select-selection__rendered
  .ant-select-selection__placeholder {
  display: block !important;
}
