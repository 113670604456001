/*** -----------------------------------------

                  IMAGE UPLOADER

----------------------------------------- ***/

.image-uploader {
  position: relative;
  display: inline-block;
}
.image-uploader > input {
  position: absolute;
  z-index: 1;
  opacity: 0;
  width: 100%;
  left: 0;
  cursor: pointer;
}
.image-error {
  color: #f00;
  font-size: 12px;
  line-height: normal;
}
.imageDisplay {
  position: relative;
}
.imageDisplay .anticon.anticon-close {
  position: absolute;
  cursor: pointer;
  color: #fff;
  background: #999999;
  padding: 4px;
  border-radius: 50%;
  font-size: 10px;
  margin: 0 -10px;
  transition: all 0.3s ease-out;
}
.imageDisplay .anticon.anticon-close:hover {
  opacity: 0.8;
}

/*** -----------------------------------------

                FILE UPLOADER

----------------------------------------- ***/

.file-upload .image-uploader {
  text-align: center;
  border: 1px solid #dcdcdc;
  font-size: 14px;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 5px;
  padding: 4px 10px;
  height: 50px;
}
.file-upload .image-uploader .photo-link {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  color: #00a7ff;
  cursor: pointer;
}
.file-upload .image-uploader .photo-link .anticon-file-add {
  background: #e2e2e2;
  padding: 6px;
  border-radius: 100%;
  margin-right: 10px;
  color: #fff;
}
.file-list .file {
  background: #f8f8f8;
  border: 1px solid #dadada;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 11px;
  color: #646464;
  position: relative;
  margin-bottom: 5px;
  min-height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
}
.file-list .file .file-close {
  position: absolute;
  top: auto;
  right: 10px;
  cursor: pointer;
  color: #a3a3a3;
}
.file-list .file .file-type-icon .anticon {
  font-size: 24px;
}
.file-list .file .file-type-icon .anticon-file-jpg {
  color: #0097a7;
}
.file-list .file .file-type-icon .anticon-file-pdf {
  color: red;
}
.file-list .file .file-type-icon .anticon-file-text,
.file-list .file .file-type-icon .anticon-file-word,
.file-list .file .file-type-icon .anticon-file-excel {
  color: #1589ce;
}
.file-list .file .file-txt {
  display: flex;
  flex-direction: column;
  line-height: 15px;
  margin: 5px 8px 5px;
  font-family: 'Source Sans Pro', sans-serif;
}
.file-list .file .file-txt .file-name {
  color: #696969;
  text-overflow: ellipsis;
  width: 50px;
  overflow: hidden;
  white-space: nowrap;
}
.file-list .file .file-txt .file-size {
  color: #a1a1a1;
}
.file-list .file > a {
  display: table;
  width: 100%;
}
.file-list .file > a .file-type-icon,
.file-list .file > a .file-txt {
  display: flex;
  align-items: center;
}
.file-list .file > a .file-txt {
  max-width: 260px;
  font-size: 13px;
  word-break: break-all;
}
.drag-enable {
  color: #000;
}
.delete-image {
  background: rgba(255, 3, 9, 1);
  font-family: 'Source Sans Pro';
  font-size: 12px;
  color: #fff;
  border: 1px solid #e30308;
  border-radius: 4px;
  padding: 2px 14px;
  text-decoration: none;
  height: 20px;
  line-height: 14px;
  transition: 0.3s ease-out;
  cursor: pointer;
}
.delete-image:hover {
  background: rgba(255, 3, 9, 0.8);
}
.del-icon.del-icon {
  border: 1px solid #99b445;
  border-radius: 100px;
  margin-left: 10px;
  height: 22px;
  width: 22px;
  cursor: pointer;
  font-size: 12px;
  color: red;
  line-height: 20px;
  padding-top: 2px;
}

/*** -----------------------------------------

              UPLOAD LOGO

----------------------------------------- ***/

.upload-logo .uploader {
  display: flex;
  position: relative;
}
.upload-logo .image-uploader {
  cursor: pointer;
  margin: 0 0 0 1em;
}
.upload-logo .uploader .ant-avatar.ant-avatar-icon {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 100px;
  border: 1px solid rgba(204, 204, 204, 0.2);
}
.upload-logo .uploader .ant-avatar.ant-avatar-icon > img {
  object-fit: contain;
}
.upload-logo .uploader .photo-link {
  font-family: 'Source Sans Pro';
  font-size: 12px;
  color: #393939;
  border: 1px solid #99b445;
  border-radius: 4px;
  padding: 2px 14px;
  text-decoration: none;
  cursor: pointer;
}
.upload-logo .uploader .delete-image {
  margin: 10px 0 0 14px;
}
.upload-logo .uploader .del-icon.del-icon {
  margin-top: 9px;
}
.upload-logo .uploader .image-error {
  position: absolute;
  bottom: -16px;
  left: 0;
}

/*** -----------------------------------------

        BANNER VIEW IMAGE AND VIDEO

----------------------------------------- ***/

.banner-full-view .image-uploader,
.banner-full-view .live-preview,
.banner-full-view .ant-avatar {
  width: 100%;
  height: 250px !important;
  background: #fdfdfd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}
.banner-full-view .ant-avatar {
  height: 248px;
}
.banner-full-view .ant-avatar > img {
  object-fit: contain;
}
.banner-full-view .image-uploader > input {
  height: 100%;
}
.banner-full-view .live-preview {
  position: relative;
  background: transparent;
  border: 1px solid #dcdcdc;
  margin-bottom: 5px;
}
.banner-full-view .live-preview > video {
  /* height: 100%;
  width: 100%; */
}
.banner-full-view .live-preview .anticon-close {
  position: absolute;
  top: 0;
  right: 0;
  background: #fdfdfd;
  border: 1px solid #dcdcdc;
  padding: 10px;
  border-radius: 2px;
  font-size: 20px;
  margin: 5px;
  cursor: pointer;
  transition: 0.3s ease-out;
}
.banner-full-view .live-preview .anticon-close:hover {
  background: #f5f5f5;
}
.banner-full-view .image-uploader .photo-link .anticon-file-add {
  padding: 12px;
  font-size: 20px;
}
.banner-full-view .image-uploader .photo-link {
  display: flex;
}
.banner-full-view .image-uploader .photo-link .uploader-text {
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  line-height: 22px;
}

/*** -----------------------------------------

          BANNER VIEW UPLOAD IMAGE

----------------------------------------- ***/

.banner-upload-img .photo-link {
  display: flex;
  flex-direction: column;
}
.banner-upload-img .photo-link .icon-upload-img {
  font-size: 50px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 25px;
}
.banner-upload-img .photo-link .uploader-text > span {
  text-align: center;
  border: 1px solid #99b445;
  color: #99b445;
  height: 32px;
  padding: 0 30px;
  border-radius: 100px;
  line-height: 30px;
}
.banner-upload-img .photo-link .uploader-text .drag-enable,
.banner-upload-img .photo-link .uploader-text .text-muted {
  display: none;
}

/*** -----------------------------------------

          UPLOAD BANNER IMAGE

----------------------------------------- ***/

.upload-banner-img.upload-banner-img {
  border: 0;
  background: transparent;
}
.upload-banner-img.upload-banner-img .ant-avatar.ant-avatar-icon {
  width: 150px;
  border-radius: 2px;
  border: 1px solid rgba(204, 204, 204, 0.2);
}
.upload-banner-img.upload-banner-img .ant-avatar.ant-avatar-icon > img {
  object-fit: contain;
}
.upload-logo .upload-banner-img .image-error {
  font-size: 12.5px;
  bottom: -20px;
}

/* CROPPER IMAGE */

.cropper-image {
  display: flex;
}
