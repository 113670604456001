.gopathways_slider {
  display: flex !important;
  align-items: center;
}

.gopathways_slider span {
  font-size: 25px;
}
.gopathways_slider i {
  font-size: 25px;
}
.gopathways_slider .slick-list {
  width: 100%;
}
.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
