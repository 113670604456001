.two-rows-label .ant-form-item-label {
  padding: 4px 0 !important;
}
.two-rows-label .ant-form-item-label label {
  white-space: normal;
  display: inline-block;
  /* margin-left: 22px; */
  text-align: center;
  line-height: 20px;
}
.two-rows-label .ant-form-item-label label:after {
  content: none !important;
}
.two-rows-label .ant-form-item-label label.ant-form-item-required:before {
  position: absolute;
  left: -7px;
}

.occupation-form-item {
  margin-bottom: 0px !important;
  display: flex !important;
  font-size: 14.6px !important;
  color: #333333 !important;
  background-color: #f5faff !important;
  padding: 20px 50px !important;
  margin-top: 20px !important;
}
.occupation-form-item .ant-form-item-label label {
  padding-right: 10px;
}

.steps-headers {
  padding: 0 40px;
  border: 0;
}

.steps-headers .ant-select {
  width: 600px;
}

.border-bottom {
  border-bottom: 0px !important;
  padding-left: 20px !important;
}

/* .steps-header [class*='ant-col-lg-8'] {
  width: 95%;
} */
