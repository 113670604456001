.CourseMap {
  padding: 25px 0px 50px;
}
.CourseMap.end {
  margin-bottom: 200px;
}
.CourseMap .steps-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: space-between;
  padding: 2%;
  padding-bottom: 2.5%;
  min-height: 200px;
}
.CourseMap .steps-container > ._no-courses {
  color: #777777;
  font-size: 16px;
  margin: 0 auto;
}
.CourseMap .steps-container > ._no-courses > i {
  margin-right: 5px;
}
.CourseMap .steps-container > .step-item-outer {
  position: relative;
  width: 23%;
  margin-bottom: 10px;
}
.CourseMap .steps-container > .step-item-outer._active > .step-item {
  box-shadow: 0px 0px 0px 1px #ffb40a;
  border-color: #ffb40a;
}
.CourseMap .steps-container > .step-item-outer > .step-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 180px;
  min-height: 180px;
  max-height: 180px;
  border: 2px solid #d9d9d989;
  border-radius: 9px;
  position: relative;
  cursor: pointer;
  transition: 0.123s all;
  z-index: 5;
}
.CourseMap .steps-container > .step-item-outer > .step-item:hover {
  box-shadow: 0px 0px 0px 1px #ffb40a;
  border-color: #ffb40a;
}
.CourseMap .steps-container > .step-item-outer > .step-item > ._top {
  height: 125px;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._top
  > ._progress {
  position: absolute;
  display: none;
  bottom: 5px;
  width: 100%;
  margin: 0px;
  height: 5px;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._top
  > ._progress
  .ant-progress-bg {
  border-radius: 0px !important;
  height: 5px !important;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._top
  > .status_icon {
  position: absolute;
  top: 11px;
  right: 11px;
  border-radius: 50em;
  width: 18px;
  height: 18px;
  background-color: #67af50;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._top
  > .status_icon
  > i {
  color: #ffffff;
  font-size: 11px;
}
.CourseMap .steps-container > .step-item-outer > .step-item > ._top > ._index {
  position: absolute;
  bottom: 45%;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  border: 2px solid #67af50;
  color: #67af50;
  font-weight: 600;
  font-size: 18px;
}
.CourseMap .steps-container > .step-item-outer > .step-item > ._top > ._type {
  position: absolute;
  bottom: 69%;
  left: -2px;
  background-color: #f4f4f4;
  padding: 1px 5px;
  height: 22px;
  font-size: 12px;
  max-width: calc(100% - 36px - 10px);
  border-left: 4px solid #67af50;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._top
  > ._type:after {
  border-right: 5px solid #fff;
  border-top: 10px solid transparent;
  border-bottom: 11px solid transparent;
  position: absolute;
  content: '';
  top: 0;
  left: 100%;
  background-color: #f4f4f4;
  height: 100%;
}
.CourseMap .steps-container > .step-item-outer > .step-item > ._top > ._id {
  bottom: 3px;
  color: #333;
  font-size: 17px;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 0px;
  white-space: nowrap;
  text-align: center;
  margin-top: 35px;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._top
  > ._id
  > ._units {
  white-space: nowrap;
  font-size: 16px;
  color: #727272;
  font-weight: 400;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item.status-completed
  > ._top
  > ._progress,
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item.status-progress
  > ._top
  > ._progress {
  display: block;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item.status-completed
  > ._top
  > .status_icon,
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item.status-progress
  > ._top
  > .status_icon {
  display: flex;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item.status-progress
  > ._top
  > ._progress
  .ant-progress-bg {
  background-color: #ee8052 !important;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item.status-progress
  > ._top
  > .status_icon {
  background-color: #ee8052;
}
.CourseMap .steps-container > .step-item-outer > .step-item > ._bottom {
  background-color: #fafafa;
  flex-grow: 1;
  border-top: 2px solid #d9d9d935;
  padding: 7px 10px;
  color: #444;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 9px 9px;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._bottom
  > ._title {
  font-size: 15px;
  color: #666;
  display: flex;
  justify-content: center;
  width: 100%;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._bottom
  > ._title
  > div {
  text-align: left;
  display: inline-block;
  margin-top: -5px;
  width: 100%;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._bottom
  > ._title
  > ._sgmnet {
  font-size: 14px;
  color: #888888;
  font-style: italic;
  display: flex;
  align-items: center;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._bottom
  > ._title
  > ._sgmnet
  > i {
  color: #6eac5f;
  margin-right: 5px;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._bottom
  > ._title
  > ._course {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._bottom
  > ._title
  > ._course
  > ._name {
  width: 90%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._bottom
  > ._title
  > ._course
  > ._units {
  white-space: nowrap;
  margin-top: 10px;
}
.CourseMap .steps-container > .step-item-outer > .step-item > ._arrow {
  position: absolute;
  height: 22px;
  top: calc(50% - 11px);
  left: 100%;
  display: flex;
  align-items: center;
  width: 74px;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._arrow
  > ._dotted {
  border-top: 2px dotted #aaa;
  width: calc(100% - 12px);
  margin-left: 9px;
}
.CourseMap .steps-container > .step-item-outer > .step-item > ._arrow > svg {
  right: 0px;
  width: 12px;
  height: 12px;
  position: absolute;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._arrow
  > svg
  > path {
  fill: #aaa;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item.color-orange
  > ._top
  > ._index {
  border-color: #ee8052;
  color: #ee8052;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item.color-orange
  > ._top
  > ._type {
  border-color: #ee8052;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item.color-blue
  > ._top
  > ._index {
  border-color: #64a8dc;
  color: #64a8dc;
}

.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._top
  > ._type.core {
  border-color: #00aef0 !important;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._top
  > ._type.general {
  border-color: #de4279 !important;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item
  > ._top
  > ._type.elective {
  border-color: #ffb40a !important;
}

.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item.Program
  > ._top
  > ._type {
  border-color: #496ce0;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > .step-item.Elective
  > ._top
  > ._type {
  border-color: #00a1e9;
}
.CourseMap .steps-container > .step-item-outer > ._milestone-indicator {
  height: 52px;
  width: 44px;
  border-radius: 5px;
  position: absolute;
  bottom: calc(50% + 23px);
  right: -23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 6;
}
.CourseMap .steps-container > .step-item-outer > ._milestone-indicator > img {
  width: 40px;
  height: 52px;
  border-radius: 5px;
}
.CourseMap
  .steps-container
  > .step-item-outer
  > ._milestone-indicator
  > ._count {
  position: absolute;
  top: -7px;
  right: -7px;
  width: 14px;
  height: 14px;
  background-color: #64a8dc;
  border-radius: 50em;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CourseMap .steps-container > ._box-outline {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4abaed, #fb55e4);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: double 1px transparent;
  border-radius: 20px;
}
.CourseMap .steps-container > ._box-outline > ._ttl {
  position: absolute;
  height: 42px;
  top: -21px;
  left: 56px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-weight: 600;
  background-color: #ffffff;
}
.CourseMap .steps-container > ._box-outline > ._end-award {
  position: absolute;
  top: calc(100%);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.CourseMap .steps-container > ._box-outline > ._end-award.end {
  height: 300px;
}
.CourseMap .steps-container > ._box-outline > ._end-award.end > ._arw {
  margin-bottom: 50px;
  margin-top: -50px;
}
.CourseMap .steps-container > ._box-outline > ._end-award > ._award {
  display: flex;
  flex-direction: column;
}
.CourseMap .steps-container > ._box-outline > ._end-award > ._award > img {
  height: 80px;
}
.CourseMap .steps-container > ._box-outline > ._end-award > ._arw {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CourseMap .steps-container > ._box-outline > ._end-award > ._arw > ._dot {
  position: absolute;
  height: 21px;
  border-left: 2px dotted #cccccc;
}
.CourseMap .steps-container > ._box-outline > ._end-award > ._arw > svg {
  position: absolute;
  width: 9px;
  height: 9px;
  transform: rotateZ(90deg);
  fill: #777777;
  margin-top: 21px;
  margin-left: 0px;
}
.CourseMap .steps-container > ._path-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  margin-top: calc((100px) + 61px);
  z-index: 0;
}
.CourseMap .steps-container > ._path-container > ._path {
  width: 100%;
  min-height: calc(200px + (2 * 61px));
  max-height: calc(200px + (2 * 61px));
  height: calc(200px + (2 * 61px));
  position: relative;
}
.CourseMap .steps-container > ._path-container > ._path > div {
  position: absolute;
}
.CourseMap .steps-container > ._path-container > ._path > div._right {
  height: 50%;
  right: -3px;
  min-width: 2px;
  border-right: 2px dotted #bbb;
}
.CourseMap .steps-container > ._path-container > ._path > div._left {
  height: 50%;
  top: 50%;
  left: -5px;
  min-width: 2px;
  border-right: 2px dotted #bbb;
}
.CourseMap .steps-container > ._path-container > ._path > div._middle {
  top: 50%;
  right: 0px;
  width: 100%;
  min-height: 2px;
  border-top: 2px dotted #bbb;
}
.CourseMap .steps-container > ._path-container > ._path > div._bottom-left {
  bottom: 0px;
  left: -5px;
  width: 15px;
  min-height: 2px;
  border-top: 2px dotted #bbb;
}
.CourseMap .steps-container > ._path-container > ._path > div._top-right {
  top: 0px;
  right: -3px;
  width: 21px;
  min-height: 2px;
  border-top: 2px dotted #bbb;
}
.CourseMap .steps-container > ._path-container > ._path > div._arw {
  bottom: -2px;
  left: 11px;
  width: 12px;
  height: 12px;
}
.CourseMap .steps-container > ._path-container > ._path > div._arw > svg {
  width: 12px;
  height: 12px;
}
.CourseMap
  .steps-container
  > ._path-container
  > ._path
  > div._arw
  > svg
  > path {
  fill: #bbb;
}

.__PopOverContentOuter {
  color: #fff;
  width: 800px;
  maxwidth: 800px;
  z-index: 999;
}
.__PopOverContentOuter > .ant-popover-content > .ant-popover-inner {
  box-shadow: 0 2px 20px 6px rgba(0, 0, 0, 0.04) !important;
}
.__PopOverContentOuter > .ant-popover-content > .ant-popover-arrow {
  background-color: #ffffff;
  box-shadow: none;
}
.__PopOverContentOuter.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow {
  left: 79px !important;
  width: 24px !important;
  height: 24px !important;
  top: 0px !important;
  background-color: white;
}
.__PopOverContentOuter.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow {
  right: 90px !important;
  width: 24px !important;
  height: 24px !important;
  top: 0px !important;
  background-color: white;
}
.__PopOverContentOuter.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow {
  right: 90px !important;
  width: 24px !important;
  height: 24px !important;
  bottom: 0px;
  left: 65px;
  background-color: white;
}
.__PopOverContentOuter .__PopOverContent {
  padding: 15px;
  font-family: 'Source Sans Pro', sans-serif;
}
.__PopOverContentOuter .__PopOverContent > .close-btn {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 28px;
  color: #000;
  cursor: pointer;
}
.__PopOverContentOuter
  .__PopOverContent
  > .close-btn
  > .fa-angle-times::before {
  content: '\F105\F104';
  letter-spacing: -0.15em;
}
.__PopOverContentOuter .__PopOverContent > .__ttl-bar > .__ttl {
  width: 98%;
  font-size: 24px;
  color: #222;
}
.__PopOverContentOuter .__PopOverContent > .__ttl-bar > .__sub-ttl {
  font-size: 18px;
  color: #777;
  font-weight: 300;
  margin-top: 10px;
}
.__PopOverContentOuter .__PopOverContent > .__ttl-bar > .__sub-ttl1 {
  font-size: 14px;
  font-weight: 300;
  margin-top: 10px;
  color: #777;
  white-space: pre-wrap;
}
.__PopOverContentOuter .__PopOverContent > ._top-section {
  margin: 15px 0px;
}
.__PopOverContentOuter .__PopOverContent > ._top-section > div {
  font-size: 19px;
  font-weight: 300;
  color: #222;
  margin-top: 0px;
}
.__PopOverContentOuter .__PopOverContent > ._sub-section {
  font-weight: 300;
  color: #222;
}
.__PopOverContentOuter .__PopOverContent > ._bottom-section {
  margin: 39px 0px;
}
.__PopOverContentOuter .__PopOverContent > ._bottom-section > .show-info-false {
  font-size: 14px;
  display: none;
}
.__PopOverContentOuter .__PopOverContent > ._bottom-section > .show-info-true {
  font-size: 14px;
  font-weight: 600;
  color: #444;
  margin: 10px 0px;
}
.__PopOverContentOuter
  .__PopOverContent
  > ._bottom-section
  > .show-info-true
  > span {
  font-weight: 300;
}
.__PopOverContentOuter .__PopOverContent._segment > .__ttl-bar > p {
  font-size: 18px;
  color: #000000;
  font-weight: 300;
}
.__PopOverContentOuter .__PopOverContent._segment > .__ttl-bar > ._list {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.2px;
  color: #000000;
}
.__PopOverContentOuter .__PopOverContent._segment > .__ttl-bar > ._list > i {
  font-size: 14px;
}
.__PopOverContentOuter .__PopOverContent._elective > ._bottom-section {
  margin: 19px 0px;
}
.__PopOverContentOuter .__PopOverContent._elective > ._bottom-section > p {
  font-size: 18px;
  color: #000000;
  font-weight: 300;
}

.toggle-view {
  font-family: 'Source Sans Pro';
  display: flex;
  justify-content: flex-end;
  padding: 20px 25px 10px;
  color: #000000;
  font-size: 16px;
}
.toggle-view > span {
  margin-left: 5px;
  margin-right: 5px;
}

.toggle-view > span.active {
  color: #8eb016;
}
.course-map-list-view .no-course-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 252px;
}
.course-map-list-view .no-course-container {
  box-sizing: border-box;
  height: 131px;
  width: 250px;
  border: 1px solid #a7daeb;
  background-color: #fbfeff;
  color: #3a3a3a;
  font-family: 'Source Sans Pro';
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

/* .custom-collapse.education-tabs {
  margin-top: 30px;
} */

.custom-collapse.education-tabs .nocourse-map {
  background-color: #f0f0f0;
  text-align: center;
  font-size: 16px;
  padding: 30px;
}
.custom-collapse.education-tabs
  > .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-content,
.custom-collapse.education-tabs
  > .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  border: 1px solid #d9d9d9;
  border-radius: 0;
}
.education-tabs.custom-collapse
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-content {
  border-top: 0;
}

.select-program-dropdown {
  width: 50%;
  position: absolute;
}

.select-program-dropdown .ant-select {
  margin-left: 5px;
  width: 270px;
  height: 55px;
}
.select-program-dropdown .select-program-label {
  color: #393939;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 300;
}

.select-program-dropdown .ant-select .ant-select-selection {
  height: 100%;
}
.select-program-dropdown .program-title,
.program-dropdown-options .program-title {
  /* color: #000000; */
  color: #393939;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 25px;
}
.select-program-dropdown .program-college,
.program-dropdown-options .program-college {
  /* color: #A0A4B5; */
  color: #808080;
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  line-height: 22px;
}
