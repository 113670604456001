.courseContainer {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(154, 154, 154, 0.65);
}

.all-courses-list > .non-collapse.courseContainer > .courseHeader {
  background-color: #2b5676;
  color: #fff;
  font-size: 16px;
  padding: 12px 15px;
  font-family: 'Source Sans Pro' !important;
}

.courseContainer .courseSearch {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  display: flex;
}

.courseContainer .courseSearch .search-placeholder {
  margin-left: 0;
  width: 100%;
}
.courseContainer .courseSearch .search-placeholder > input {
  width: 100%;
  padding: 4px 25px;
  border-color: rgb(180, 210, 85);
  border-radius: 5px;
}
.courseContainer .list {
  min-height: 80px;
  max-height: 400px;
}

.courseContainer .list .courseHeader {
  border-bottom: 1px solid #ddd;
  padding: 10px 17px;
}

.list .courseHeader > .courese_id {
  color: #3a3a3a;
  font-weight: 700;
  font-size: 13px;
  font-family: 'Source Sans Pro' !important;
}
.list .courseHeader > .course_units {
  color: #3a3a3a;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  font-family: 'Source Sans Pro' !important;
}

.courseItem .course-units {
  text-align: center;
}

.list .courseHeader.floating,
.courseContainer .list .courseHeader.floating {
  background-color: rgb(241, 241, 241);
  font-weight: 500;
}

.courseContainer .list .content .List {
  width: initial !important;
}

.courseContainer .list .content .courseHeader.courseItem:hover {
  background-color: #def3d3;
}

.all-courses-list
  > .courseContainer.ant-collapse
  > .courseHeader.ant-collapse-item {
  padding: 0;
}
.all-courses-list
  > .courseContainer.ant-collapse
  > .courseHeader.ant-collapse-item
  > .ant-collapse-header {
  background-color: rgb(43 86 118);
  padding: 12px;
  padding-left: 35px;
  color: #ffffff;
  font-size: 14px;
  font-variant: tabular-nums;
  font-weight: 500;
  line-height: initial;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
}

.all-courses-list
  > .courseContainer.ant-collapse
  > .courseHeader.ant-collapse-item
  > .ant-collapse-content {
  padding: 0;
}

.all-courses-list
  > .courseContainer.ant-collapse
  > .courseHeader.ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  display: initial;
}

.courseContainer .course-id {
  padding-left: 0;
}
.courseContainer .course-id > .icon-icuparrow {
  margin-right: 5px;
  position: relative;
  top: 1px;
  color: #ffbf2d;
}

.noSearch,
.onLoading {
  padding: 20px;
  display: block;
  text-align: center;
  background: rgb(241, 240, 240);
}

.all-courses-list
  > .disableCollapse.courseContainer.ant-collapse
  > .courseHeader.ant-collapse-item
  > .ant-collapse-header {
  pointer-events: none;
  padding-left: 10px;
}
.all-courses-list
  > .disableCollapse.courseContainer.ant-collapse
  > .courseHeader.ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  display: none;
}

.all-courses-list .anticon.anticon-check-circle.item-icon.checked {
  color: #64b927;
  margin-left: 5px;
  margin-top: 4px;
}
