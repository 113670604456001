.index {
  color: #333333;
  font-size: 14px;
}

.fillCircle {
  fill: #e4e4e4;
  cursor: pointer;
}
.fillCircle.progress {
  fill: #ffb40a;
}
.fillCircle.completed {
  fill: #91b755;
}

.shape {
  fill: #ffb40a;
  opacity: 0.15;
  border-radius: 0%;
}
.shape:hover {
  opacity: 0.35;
  z-index: 100;
}

.scale {
  fill: #fafafa;
  stroke: #999;
  stroke-width: 0.15;
}

.axis {
  stroke: #555;
  stroke-width: 0.15;
}

/* .icon {
  background-color: #ffb40a;
  border-radius: 50%;
  cursor: pointer;
} */

.caption {
  fill: #555555;
  font-weight: 400;
  font-size: 14px;
}
.caption.title {
  font-size: 14px;
  text-anchor: middle;
  font-weight: 300;
  font-family: 'Source Sans Pro', sans-serif;
}
.caption.title.progress {
  font-weight: 600;
  font-size: 16px;
  fill: #ffb40a;
}
.caption.title.completed {
  fill: #91b755;
  font-size: 16px;
  font-weight: 600;
}

.chart-tooltip {
  position: absolute;
  width: 100px;
  padding: 2px 6px;
  background-color: rgba(51, 51, 51, 0.8);
  border-radius: 10px;
  color: #fff;
}
.chart-tooltip > .chart-tooltip-caption {
  display: block;
  font-size: 10px;
  text-align: center;
}
.chart-tooltip > .chart-tooltip-arrow {
  position: absolute;
  bottom: -4px;
  left: 44px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(51, 51, 51, 0.8);
}
