.radarChart {
  max-width: 100%;
  height: auto;
  overflow-x: auto;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .radarChart {
    display: none;
  }
}
