.custom-select {
  position: relative;
  display: inline-block;
  z-index: 1000;
}
.custom-select > .label-container {
  min-height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  overflow: hidden;
  box-sizing: border-box;
  max-height: 32px;
}
.custom-select .group-title {
  padding: 0px 11px;
  font-size: 15px;
  color: #000;
  margin-top: 5px;
}
.custom-select > .label-container > .c-label {
  margin: 0 11px;
}
.custom-select:not(.is-multi) > .label-container > .c-label {
  margin: 0 11px;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
  position: absolute;
  align-items: center;
  white-space: nowrap;
  width: calc(100% - 30px);
}
.custom-select > .label-container > .c-s-placeholder {
  opacity: 0.4;
  position: absolute;
  margin: 0 11px;
}
.custom-select > .label-container > .c-closable {
  position: absolute;
  right: 14px;
  width: 13px;
  height: 13px;
  cursor: pointer;
  z-index: 11;
  border-radius: 50em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-select > .label-container > .c-closable:hover {
  background-color: #f0f0f0;
  box-shadow: 0px 0px 0px 2px #f0f0f0;
}
.custom-select > .label-container > .c-closable::after {
  content: '';
  height: 13px;
  width: 1px;
  /* margin-left: 12px; */
  background-color: #888;
  transform: rotate(45deg);
  z-index: 1;
  position: absolute;
  left: 6px;
}
.custom-select > .label-container > .c-closable::before {
  content: '';
  height: 13px;
  width: 1px;
  background-color: #888;
  transform: rotate(135deg);
  z-index: 2;
  position: absolute;
  left: 6px;
}
.custom-select > .label-container > input {
  position: absolute;
  left: 0;
  top: 0;
  outline: none;
  border: none;
  height: 100%;
  margin: 0 11px;
  width: calc(100% - 22px);
  background-color: transparent;
}
.custom-select > .dropdown-container {
  position: relative;
  top: calc(100% + 3px);
  width: 100%;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  padding: 7px 0px;
  display: none;
  background-color: #fff;
  z-index: 888;
}
.custom-select > .dropdown-container > .options-list {
  max-height: 230px;
  overflow-y: auto;
}
.custom-select > .dropdown-container .c-s-option {
  padding: 8px 19px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 22px;
}
.custom-select > .dropdown-container .c-s-option span {
  line-height: 15px;
}
.custom-select > .dropdown-container .c-s-option.is-selected {
  background-color: #f2ffe4;
}
.custom-select > .dropdown-container .c-s-option:hover {
  background-color: #f6f6f6;
}
.custom-select > .dropdown-container .c-s-option.is-disabled {
  opacity: 0.3;
  pointer-events: none;
}

/* events */
.custom-select.is-focused:not(.has-search-term):not(.is-multi)
  > .label-container
  > .c-label {
  opacity: 0.2;
}
.custom-select.has-search-term:not(.is-multi) > .label-container > .c-label {
  opacity: 0;
}
.custom-select > .label-container > .c-label input {
  border: none;
  outline: none;
  height: 100%;
  position: relative;
  top: 3px;
}
.custom-select > .label-container > .c-label input::placeholder {
  color: #bbb;
}
.custom-select.is-focused > .dropdown-container {
  display: block;
}
.custom-select.is-loading > .dropdown-container > .options-list,
.custom-select.is-loading > .dropdown-container > .options-list > .c-s-footer {
  opacity: 0.4;
  pointer-events: none;
}
.custom-select.is-multi > .label-container {
  max-height: initial !important;
  padding-top: 3px;
  padding-bottom: 3px;
  z-index: 222;
}
.custom-select.is-multi > .label-container > .c-label {
  display: flex;
  flex-wrap: wrap;
  z-index: 111;
}
.custom-select.is-multi > .label-container > .c-label > span {
  margin-top: 3px;
  margin-bottom: 3px;
  z-index: 22;
}
