.pathcard {
  position: relative;
  /* width: 230px; */
  display: inline-block;
  padding: 0;
}
.pathcard li {
  margin-bottom: 0;
  /* width: 100%; */
  list-style: none;
  display: inline-block;
  /* width: 230px; */
  background: #fff;
  box-shadow: 0 0 4px rgb(38 29 62 / 14%);
  border-radius: 30px;
  margin: 0 10px 20px;
  vertical-align: top;
  position: relative;
  font-family: 'Arial Rounded MT Bold';
}
.pathcard li img {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  width: 100%;
  height: 133px;
}
.pathcard h5 img {
  width: 24px;
  position: relative;
  top: -3px;
  height: auto;
}
.pathcard li .avatar {
  position: absolute;
  top: 110px;
  left: 10px;
}
.pathcard li .avatar span {
  background: #fff;
}
