.award-search .ant-form-item {
  display: flex;
}
.award-search .ant-form-item-label {
  padding-right: 10px;
  overflow: inherit;
}
.award-search .ant-form-item-control-wrapper {
  width: 100%;
}
.award-search
  .ant-select:not(.ant-select-open)
  .ant-select-selection__rendered
  .ant-select-selection__placeholder {
  display: block !important;
}
.award-search .award-tag {
  border-color: rgba(68, 147, 226, 1) !important;
}
