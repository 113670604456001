/*** -----------------------------------------

          NO RESULTS & INITIAL LOADING

----------------------------------------- ***/

.anticon-loading {
  font-size: 24px;
}
.no-results-text,
.initial-loading.initial-loading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  margin: 24px;
}
.initial-loading > .small-request-loader {
  display: block;
  text-align: center;
  padding: 15px 0;
}
.initial-loading > .small-request-loader > .loader-text {
  margin-left: 16px;
}
.initial-loading > .error-text {
  color: #f5222d;
}
.initial-loading > .error-text {
  color: #f5222d;
  font-size: 16px;
}

/*** Loader Text with Overlay Screen ***/

.ant-layout-content {
  position: relative;
}
.loader-text-overlay.loader-text-overlay {
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75)
  );
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  z-index: 20;
  padding-top: 225px;
  padding-left: calc(-4rem + 200px);
}

/*** -----------------------------------------

              NO RESULTS MESSAGE

----------------------------------------- ***/

.no-results-msg {
  background: #f8f8f8;
  padding: 3em;
  text-align: center;
  border-radius: 2px;
  margin: 24px;
  clear: both;
}
.no-results-msg > span {
  display: block;
}
.no-results-msg > .no-result-icon {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  border: 1px solid rgba(204, 204, 204, 0.5);
  background: #fff;
  font-size: 40px;
  line-height: 70px;
  margin: 0 auto 10px auto;
}
.no-results-msg > .head {
  font-size: 18px;
  font-weight: bold;
}
.no-results-msg > .sub-head {
  font-size: 16px;
}

/*** -----------------------------------------

              NO RESULTS TEXT

----------------------------------------- ***/

.no-results-text.no-results-text.no-results-text.no-results-text {
  padding: 1.5em 1em;
  width: 100%;
}
.no-results-text > .no-result-icon,
.no-results-text > .head {
  display: none;
}
