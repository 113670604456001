.program-study-selector .ant-form-item-label label:after {
  content: '';
}

.program-study-selector .tags-s .ant-form-item {
  margin-bottom: 0;
}

.program-study-selector .tags-s .has-error .ant-form-explain {
  margin-top: -23px;
  padding-left: 10px;
}

.program-study-selector .custom-add-program-of-study {
  display: flex;
}

.program-study-selector
  .custom-add-program-of-study
  .ant-form-item-control-wrapper {
  flex-grow: 1;
}

.program-study-selector.filter-section > .selected-section {
  padding: 10px;
}

.program-study-selector.filter-section > .selected-section > .tags-s {
  margin-top: 0;
}
.program-study-selector .searchByProgram .ant-form-item {
  display: flex;
}
.program-study-selector .searchByProgram .ant-form-item-label {
  padding-right: 10px;
  overflow: inherit;
}
.program-study-selector .searchByProgram .ant-form-item-control-wrapper {
  width: 100%;
}
