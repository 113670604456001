/*** -----------------------------------------

                SEND MAIL

----------------------------------------- ***/

.send-email.send-email {
  margin: 4em auto;
  float: none;
}
.send-email-header {
  padding: 24px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  justify-content: space-between;
}
.send-email-header > .ant-btn {
  min-width: 80px;
}
.send-email-header .btn-back {
  margin-left: -4px;
}
.send-email-header .ant-btn > .anticon + span,
.send-email-header .ant-btn > span + .anticon {
  margin-left: 2px;
}
.send-email .data-scroll {
  max-height: 160px;
  overflow-y: auto;
  float: left;
  width: 100%;
}
.send-email .label-head {
  font-family: 'Source Sans Pro', sans-serif;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  text-align: right;
}
.send-email .send-detail-row .text-ellipsis {
  max-width: 100%;
}

.has-error .tox.tox-tinymce {
  border: 1px solid red !important;
}
.tox-statusbar__branding {
  display: none !important;
}

.custom-editor label:before {
  display: inline-block;
  margin-right: 4px;
  content: '*';
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: rgb(245, 34, 45);
}

/*====  Styling Draft Editor ====== */
.stargate-editor label:before {
  display: inline-block;
  margin-right: 4px;
  content: '*';
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: rgb(245, 34, 45);
}
.custom-editor {
  border: 1px solid #ccc;
  border-radius: 0;
}
.has-error .custom-editor {
  border-color: red;
}

.custom-editor > .rdw-editor-toolbar {
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  margin-bottom: 0;
}
.custom-editor .rdw-editor-main {
  padding: 5px;
  min-height: 150px;
}

.custom-editor .public-DraftStyleDefault-block,
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  line-height: initial;
}
