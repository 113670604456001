.add-award-c {
  padding: 0 9px;
  display: flex;
  flex-flow: initial !important;
}
.add-award-c .i-c {
  flex-grow: 1;
}
.add-award-c .b-c {
}
.add-award-c .b-c button {
  border: none;
}

.ac-o-list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.ac-o-list > i {
  position: relative;
  left: -9px;
}
.ac-o-list > i:hover {
  color: #000;
}
