/* INSTITUTION CARD STYLES */

.institution-card .status {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
}

.institution-card .status .status-header,
.institution-card .status .switcher .txt {
  color: #393939;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}
.institution-card .status .switcher .txt {
  font-weight: 600;
}
.institution-card .status .switcher .txt {
  padding-left: 5px;
}
.institution-card .status .switcher .txt.readonly.active {
  color: #19a433;
}

.institution-card .card-details {
  padding-top: 14px;
  padding-bottom: 14px;
}
.institution-card .institution-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  padding-top: 20px;
}
.institution-card .institution-card-content .ant-avatar {
  font-size: 42px;
  height: 62px;
  width: 62px;
  border-radius: 50em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.institution-card .institution-card-content .ant-avatar.active {
  border: 1px solid rgb(214 214 214);
  padding: 5px;
}
.institution-card .institution-card-content .card-title {
  margin-top: 16px;
  text-align: center;
}
.institution-card .institution-card-content .card-sub-title {
  color: #00afff;
}
.institution-card
  .institution-card-content
  .card-sub-title
  .card-sub-title-parent {
  color: #777;
  font-weight: 300;
  margin-right: 4px;
}

.institution-card.clickable .data-card:hover {
  box-shadow: 0px 0px 0px 3px #99b445;
  transition: 0.1s all;
  cursor: pointer;
}
