/*** -----------------------------------------

                ASYNCHSEARCH

----------------------------------------- ***/

.asynchsearch .data-table-row > .ant-checkbox-wrapper {
  display: block;
  line-height: 40px;
}
.asynchsearch .data-table-row > .ant-checkbox-wrapper > .ant-checkbox {
  display: none;
}
.asynchsearch .ant-checkbox-wrapper + span,
.asynchsearch .ant-checkbox + span {
  padding: 0;
}
.asynchsearch .data-table-row .ant-checkbox-wrapper span .ant-avatar {
  position: relative;
  top: -1px;
}
