.skills-search .ant-form-item {
  display: flex;
}
.skills-search .ant-form-item-label {
  padding-right: 10px;
  overflow: inherit;
}
.skills-search .ant-form-item-control-wrapper {
  width: 100%;
}
.skills-search
  .ant-select:not(.ant-select-open)
  .ant-select-selection__rendered
  .ant-select-selection__placeholder {
  display: block !important;
}
.skills-search .skills-tag {
  border-color: #33d5c7 !important;
}
