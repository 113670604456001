.custom-editor {
  position: relative;
}
.custom-editor .counter {
  position: absolute;
  right: 5px;
  bottom: 10px;
}

.editor-wrapper.rdw-editor-wrapper {
  /* border: 1px solid rgb(220 220 220); */
}
.editor-wrapper.rdw-editor-wrapper.has-error {
  border-color: red;
}
.editor-wrapper.rdw-editor-wrapper > .rdw-editor-toolbar {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.rdw-editor-main {
  min-height: 150px;
  padding: 5px;
}
.rdw-link-modal,
.rdw-embedded-modal,
.rdw-image-modal,
.rdw-colorpicker-modal {
  left: 0px;
  box-shadow: none;
}
.rdw-colorpicker-modal {
  height: 300px;
}
.rdw-link-modal,
.rdw-embedded-modal,
.rdw-image-modal,
.rdw-embedded-modal-btn,
.rdw-link-modal-btn,
.rdw-image-modal-btn {
  height: auto;
}
.rdw-image-modal-size-input {
  height: 30px;
}
.rdw-image-modal-btn:disabled {
  cursor: not-allowed;
}

.rdw-dropdown-wrapper a,
.rdw-dropdown-wrapper a:hover {
  color: #000;
}
.rdw-editor-toolbar .rdw-dropdown-optionwrapper {
  list-style: none;
}
