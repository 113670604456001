.graph-results {
  margin: 0px 18px;
}
.graph-results .graph-table {
  width: 100%;
}
.graph-results .graph-table thead tr th {
  font-family: 'Arial Rounded MT Bold';
  text-align: left;
}
.graph-results .graph-table thead tr th:first-child {
  padding-left: 15px;
  vertical-align: top;
}
.graph-results .graph-table .dividerLine {
  border-left: solid 2px #ccc;
}
.graph-results .graph-table tbody {
  font-family: 'Arial Rounded MT Bold';
  font-size: 13px;
  width: 100%;
}
.graph-results .graph-table tbody::before,
.graph-results .graph-table tbody::after {
  content: '@';
  display: block;
  line-height: 20px;
  text-indent: -99999px;
}
.graph-results .graph-table tbody::before {
  line-height: 0px !important;
}
.graph-results .graph-table tbody tr td {
  padding: 7px 0px;
  vertical-align: top;
}
.graph-results .graph-table tbody tr td span {
  font-size: 12px;
  overflow: hidden;
  padding: 2px 6px;
  white-space: nowrap;
  border-radius: 20px;
  text-overflow: ellipsis;
}
.graph-results .graph-table tbody tr td:nth-child(3) {
  text-align: left;
  cursor: pointer;
  vertical-align: middle;
}
.graph-results .graph-table tbody tr td:nth-child(3) div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.graph-results .graph-table tbody tr td:nth-child(3) div:hover {
  color: #3bc4ff;
}
.graph-results .graph-table tbody tr td:last-child img {
  filter: invert(0%) sepia(93%) saturate(0%) hue-rotate(234deg) brightness(96%)
    contrast(107%);
  height: 16px;
  width: 16px;
  position: relative;
  top: -2px;
  margin-right: 10px;
}
.graph-results .graph-table tbody tr td:last-child.cursor-pointer {
  cursor: pointer;
}
.graph-results .graph-table tbody tr td:last-child svg {
  font-size: 16px;
  fill: #666666;
}
.graph-results .graph-table tbody .bar-perc-h {
  display: flex;
  border-radius: 5px;
  background: #dfdfdf;
}
.graph-results .graph-table tbody .bar-perc-h .bar-perc {
  background-image: linear-gradient(
    to right,
    #6b35ee,
    #7947f2,
    #8658f5,
    #9268f9,
    #9e78fc
  );
  height: 12px;
  transform: rotate(180deg);
  border-radius: 10px;
}
.graph-results .graph-table tbody .bar-perc-h .bar-perc-count {
  text-align: right;
  position: relative;
  top: -2px;
  margin: 0px 10px 0px 2px;
}

.noSkillFound {
  background: #f9f8ff;
  border: 1px solid #eaedf1;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 20px;
}
.noSkillFound h5 {
  font-size: 19px;
  font-family: 'Arial Rounded MT Bold';
}
.noSkillFound p {
  font-size: 14px;
}
