/*** -----------------------------------------

        OVERVIEW ACCORDION CONTENT

------------------------------------------ ***/

.goAIBtn {
  float: right;
  z-index: 19;
}
.AddOverviewContent {
  position: relative;
}
.AddOverviewContent.readonly h4,
.AddOverviewContent.readonly p,
.AddOverviewContent.readonly > .list-row > .content > .loop {
  margin: 0px;
}
.AddOverviewContent.readonly .ant-form-item {
  margin-bottom: 0px;
}
.AddOverviewContent.readonly > .list-row > .content {
  margin-top: 0px;
}
.AddOverviewContent .anticon-delete {
  position: absolute;
  right: -8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.step-with-task .AddOverviewContent .anticon-delete {
  right: -12px;
  margin-top: 12px;
}
/* Duplicated css due to :
   https://stargategps.atlassian.net/browse/SRM-3732?focusedCommentId=22853
*/
.institution .ant-row.step-with-task .steps-tasks-cta-section {
  border-top: 1px solid #ddd;
  min-height: 100px;
  margin: auto -20px;
  margin-top: 2em;
  padding: 20px;
}
.path-authoring form button,
.steps-tasks-cta-section button {
  width: 134px;
}
.AddOverviewContent .btn-blue-outline.add-more {
  width: auto;
  margin-right: 10px;
}
.institution .ant-row.step-with-task {
  border: 1px solid #dddddd;
  padding: 21px;
  padding-bottom: 0;
  margin-top: 5px;
}
.AddOverviewContent .anticon-delete:hover {
  color: rgba(0, 0, 0, 0.8);
}
.step-with-task .AddOverviewContent > .list-row {
  border-radius: 0px;
}
.AddOverviewContent > .list-row {
  border: 1px dashed #33333378;
  padding: 21px;
  border-radius: 5px;
  position: relative;
  margin: 0px 11px 29px 11px;
}
.AddOverviewContent > .list-row:last-child {
  margin-bottom: 0px;
}
.AddOverviewContent > .list-row > .checkbox {
  position: absolute;
  left: -23px;
  top: -12px;
}
.AddOverviewContent > .list-row > .content {
  margin-left: 17px;
  margin-top: 17px;
}
.AddOverviewContent > .list-row > .content > .loop {
  display: flex;
  align-items: center;
  margin: 7px 0px;
}
.AddOverviewContent > .list-row > .content > .loop.has-title {
  flex-flow: column wrap;
  margin: 7px 47px 7px 0;
}
.AddOverviewContent > .list-row > .content > .loop.has-title > .form-item {
  margin-bottom: 24px;
}
.AddOverviewContent > .list-row > .content > .loop > .form-item {
  margin-bottom: 0px;
  width: 100%;
}
.AddOverviewContent > .list-row > .content > .loop > button {
  margin-left: 13px;
  border-color: #d9d9d9;
  width: 38px;
  background-color: #f8f8f8;
}
.AddOverviewContent > .list-row > .content > .loop > .delete {
  margin-left: 13px;
  margin-top: -10px;
  width: 38px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.AddOverviewContent > .list-row > .content > .loop.has-title > .delete {
  margin-left: 0;
  margin-top: 12px;
  right: 30px;
}
.AddOverviewContent > .list-row > .content > .loop > .delete:hover {
  color: rgba(0, 0, 0, 0.8);
}

.AddOverviewContent .counter {
  display: inline;
  position: absolute;
  right: 5px;
  padding-top: 10px;
}

.AddOverviewContent textarea,
.AddOverviewContent .ant-form-item-children > input {
  padding-right: 50px;
}
.AddOverviewContent .list-row .ant-form-item-control {
  z-index: 9;
}
/* .AddOverviewContent textarea {
  height: auto !important;
} */
