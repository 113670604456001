.student-list-title {
  color: #000000;
  font-family: arimo;
  font-weight: bold !important;
  font-size: 12px !important;
  margin-top: 6px !important;
}
.email-icon.email-icon {
  color: #d5dce5;
  background-color: #c0c4ca;
  border: 1px solid #adb0b6;
  border-radius: 100px;
  width: auto;
  padding: 0 20px;
  box-shadow: none;
  cursor: not-allowed;
}
.email-icon.email-icon:active,
.email-icon.email-icon:focus,
.email-icon.email-icon:hover {
  color: #d5dce5 !important;
  background-color: #c0c4ca !important;
  border: 1px solid #adb0b6 !important;
  border-radius: 100px;
}
.email-icon.email-icon.active {
  background-color: #99b445 !important;
  border-color: #87a038 !important;
  color: #fff !important;
  cursor: pointer;
  border-radius: 100px;
}
.email-icon.email-icon.active:hover {
  color: #fff !important;
  background-color: #aac652 !important;
  border-color: #aac652 !important;
}
.filter-field {
  font-family: 'Source Sans Pro', sans-serif;
  margin-left: 20px;
}
.filter-field .filter-label {
  margin-right: 10px;
}
.filter-field .ant-select {
  width: 140px;
}
.student-select {
  width: 200px;
  height: 25px;
  margin-left: 10px;
  border-color: #e8e9ec;
}
.search-field.search-field {
  width: 240px;
  padding: 0 30px;
}
.studentList .search-field {
  width: 200px;
}
.studentList.students-management-table .search-field {
  width: 300px;
}
.student-search-full {
  width: 100%;
}

.search-placeholder {
  position: relative;
  float: left;
}
.search-placeholder .close-icon {
  cursor: pointer;
  right: 0;
  top: 0;
}
.search-placeholder .search-icon,
.search-placeholder .close-icon {
  position: absolute;
  margin: 9px;
}
.ant-modal {
  border-radius: 0px !important;
}
.ant-modal-content {
  border-radius: 0px !important;
}
.message-col {
  margin-left: 58px;
}
.sms-email-form {
  padding: 0px !important;
}
.message-selector {
  color: #43425d;
  font-size: 14px;
}
.recipient-input {
  width: 170px !important;
  border-color: 1px #e8e9ec;
  border-radius: 0px !important;
}

.rec-icon {
  background: #43425d;
  width: 25px;
  margin-left: 87px;
  color: white;
  height: 25px;
  box-shadow: 2px 2px 3px #ddd;
  padding: 5px;
}

.ant-select-dropdown {
  /* text-align: right; */
  font-size: 11px !important;

  color: #4d4f5c !important;
}

/* width
.student-data-scroll::-webkit-scrollbar {
  height: 3px;
  cursor: pointer;
}*/

/* Track
.student-data-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  cursor: pointer;
}*/

/* Handle
.student-data-scroll::-webkit-scrollbar-thumb {
  background: #888;
  cursor: pointer;
}*/

/* Handle on hover
.student-data-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*** -----------------------------------------

        STUDENT LIST DATA STICKY TABLE

----------------------------------------- ***/

.sticky-table {
  font-family: Source Sans Pro;
  font-size: 13px;
}
.sticky-table-header-wrapper {
  background-color: #f5f6fa;
  font-family: arimo;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}
.sticky-table-x-wrapper .sticky-table-table {
  width: 100%;
}
.sticky-table .sticky-table-row.sticky-table-row {
  height: 40px;
  line-height: 40px;
}
.sticky-table-row:nth-child(even) {
  background: #f8f8f8;
}
.sticky-table .sticky-table-cell {
  min-width: 115px;
  padding-right: 2em;
}
.sticky-table .sticky-table-header .sticky-table-cell,
.sticky-table .sticky-table-x-wrapper .sticky-table-cell {
  text-align: center;
}
.sticky-table-corner .sticky-table-cell:first-child,
.sticky-table-column .sticky-table-cell:first-child {
  min-width: 60px;
  width: 60px;
  padding-left: 26px;
}
.sticky-table-corner .sticky-table-cell:nth-child(2),
.sticky-table-column .sticky-table-cell:nth-child(2) {
  min-width: 90px;
}
.sticky-table-corner .sticky-table-cell:nth-child(3),
.sticky-table-column .sticky-table-cell:nth-child(3) {
  min-width: 180px;
}
.sticky-table .anticon-check-circle {
  color: #52c41a;
  font-size: 15px;
}
.sticky-table .anticon-close-circle {
  color: #f5222d;
  font-size: 15px;
}
.sticky-table .icon-spinner {
  padding: 3px;
  background: #faad14;
  color: #fff;
  border-radius: 100%;
  font-size: 10px;
}
.sticky-table .icon-spinner:before {
  position: relative;
  left: 0.5px;
  top: 0.3px;
}

/*** -----------------------------------------

      STUDENT LIST DATA ANT SCROLL TABLE

----------------------------------------- ***/

.studentList .ant-table-scroll .ant-table-header {
  /* overflow-y: hidden; */
}
.studentList .ant-table-thead > tr > th {
  border-top: 0;
}
.studentList .ant-table-scroll table {
  table-layout: fixed;
}
.studentList .ant-table-scroll::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  /* background: #e5e9ef; */
  width: 17px;
  height: 49px;
  z-index: 1;
}
.studentList .ant-table-thead > tr.ant-table-row-hover > td,
.studentList .ant-table-tbody > tr.ant-table-row-hover > td,
.studentList .studentList .ant-table-thead > tr:hover > td,
.studentList .ant-table-tbody > tr:hover > td,
.studentList .ant-table-tbody > tr.ant-table-row-hover {
  background: transparent !important;
}
.studentList .ant-table-tbody > tr:nth-child(even) {
  background: #f8f8f8 !important;
}
.studentList .ant-table-tbody > tr:nth-child(odd) {
  background: #fff !important;
}
.studentList .ant-table-thead > tr > th {
  background: #e5e9ef;
  border-bottom: 0;
}
.studentList .ant-table-tbody > tr > td,
.studentList .ant-table-header tr > th {
  border-bottom: 0;
  padding: 12px 5px;
  font-size: 13px;
}
.studentList .ant-table-header tr > th {
  text-transform: uppercase;
}
.studentList .anticon-check-circle {
  color: #52c41a;
  font-size: 15px;
}
.studentList .anticon-close-circle {
  color: #f5222d;
  font-size: 15px;
}
.studentList .icon-spinner {
  padding: 3px;
  background: #faad14;
  color: #fff;
  border-radius: 100%;
  font-size: 10px;
}
.studentList .icon-spinner:before {
  position: relative;
  left: 0.5px;
  top: 0.3px;
}
.studentList .ant-table-thead > tr > th.ant-table-selection-column,
.studentList .ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: left;
  padding-left: 16px;
}
.studentList .ant-table-wrapper .ant-table-selection-menu {
  /* width: 100px; */
}
/*
.studentList
  .ant-table-scroll
  .ant-table-thead
  > tr
  > th.ant-table-selection-column,
.studentList
  .ant-table-scroll
  .ant-table-tbody
  > tr
  > td.ant-table-selection-column {
  width: 20px;
  min-width: 20px;
}
.studentList .ant-table-scroll .ant-table-thead > tr th:nth-child(2),
.studentList .ant-table-scroll .ant-table-tbody > tr td:nth-child(2) {
  min-width: 8%;
  width: 8%;
}
.studentList .ant-table-scroll .ant-table-thead > tr th:nth-child(3),
.studentList .ant-table-scroll .ant-table-tbody > tr td:nth-child(3) {
  min-width: 15%;
  width: 15%;
}
*/
.studentList .ant-table-fixed-right .ant-table-thead tr > th,
.studentList .ant-table-fixed-right .ant-table-tbody tr > td {
  text-align: center;
}
.studentList .ant-table-fixed-right,
.studentList .ant-table-fixed-right table,
.studentList .ant-table-fixed-header .ant-table-header,
.studentList .ant-table-fixed-header .ant-table-header table,
.studentList
  .ant-table-fixed-header
  .ant-table-header
  .ant-table-thead
  > tr:first-child
  > th:last-child,
.studentList .ant-table-fixed-left,
.studentList .ant-table-fixed-left table {
  border-radius: 0;
}
.studentList .ant-table-fixed-right .ant-table-header {
  background: #f5f6fa;
}
.studentList .ant-table-fixed-left .ant-table-fixed td:last-child {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.studentList .grants-table .ant-table-scroll .ant-table-thead th:last-child,
.studentList .grants-table .ant-table-scroll .ant-table-tbody td:last-child {
  display: none;
}
.studentList .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow-x: hidden;
  padding-bottom: 20px !important;
  margin-bottom: -20px !important;
}
.studentList .ant-table-fixed-left .ant-table-body-inner {
  overflow-y: hidden !important;
}
.studentList .ant-table-fixed-left,
.studentList .ant-table-fixed-right {
  /* box-shadow: none; */
}
.studentList .ant-table-placeholder {
  border: 0;
}
.students-management-main
  .studentList
  > .ant-table-wrapper
  table
  .ant-table-tbody
  tr
  td
  span.img-circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #9fa3a8;
  border-radius: 20px;
}
.students-management-main .overview-counts .count-col .label-txt {
  font-size: 15px;
}
.students-management-main .ant-table-placeholder {
  color: rgba(0, 0, 0, 0.85);
}
.students-management-main .all-student-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  padding: 1%;
}
.students-management-main
  .all-student-selection
  .ant-btn.ant-btn-link.ant-btn-round {
  border: none;
}
.students-management-main
  .all-student-selection
  .ant-btn.ant-btn-link.ant-btn-round:hover {
  color: #1890ff;
}
.students-management-main
  .all-student-selection
  .ant-btn.ant-btn-link.ant-btn-round:focus {
  color: #1890ff;
}
.students-management-main .all-student-selection div.ant-typography,
.ant-typography p {
  margin-bottom: 0;
}
.students-management-main .overview-counts .count-col {
  width: 9%;
  margin-right: 20px;
}
.students-management-main .overview-counts .count-col:before {
  height: 100%;
}
.students-management-main
  .ant-table-wrapper
  .ant-pagination.ant-table-pagination {
  float: right;
}
.students-management-main
  .ant-table-wrapper
  .ant-pagination.ant-table-pagination
  .ant-pagination-item-active {
  background: transparent;
}

.students-management-main .ant-table-wrapper .ant-pagination-item-active a,
.ant-pagination-item-active:hover a,
.ant-pagination-item-active:focus a {
  color: rgba(0, 0, 0, 0.65);
}
@media only screen and (min-device-width: 1100px) and (max-device-width: 1300px) {
  .search-field.search-field {
    width: 180px;
  }
  .institution.pathways.dashboard .w150 .field-100 {
    min-width: 160px !important;
  }
  .institution.pathways.dashboard .ant-select {
    width: 82px !important;
  }
}
