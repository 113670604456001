.program-study-selector .searchOccupation .ant-form-item {
  display: flex;
}
.program-study-selector .searchOccupation .ant-form-item-label {
  padding-right: 10px;
  overflow: inherit;
}
.program-study-selector .searchOccupation .ant-form-item-control-wrapper {
  width: 100%;
}
