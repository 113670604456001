.cards-container .add-event-button {
  /* border-radius: 15px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: #99b445 !important;
  color: #99b445 !important;
  width: 150px;
  font-size: 12px !important;
  margin-top: 18px; */
}
.cards-container .add-event-button:hover,
.cards-container .add-event-button:focus {
  /* background: #99b445 !important;
  color: #fff !important; */
}
.cards-container .add-event-button:active,
.cards-container .add-event-button.active {
  /* color: #fff !important;
  border-color: #defa89 !important;
  border-radius: 2px; */
}
.status-cards {
  clear: both;
  padding: 10px;
}
.status-cards .ant-card {
  margin: 10px;
}
.status-cards .ant-card-body {
  padding: 0;
}
.status-cards .card-icon {
  text-align: center;
  font-size: 2em;
  color: #fff;
  padding: 1em 0;
}
.status-cards .ant-card .card {
  display: flex;
}
.status-cards .card-content {
  padding: 0.5em 1.5em;
  background: #fbfbfb;
  text-align: center;
}
.status-cards .card-content .card-count,
.status-cards .card-content .card-title {
  font-family: 'Source Sans Pro', sans-serif;
  display: block;
}
.status-cards .card-content .card-count {
  font-size: 3em;
  line-height: 44px;
  color: #333333;
}
.status-cards .card-content .card-title {
  text-transform: capitalize;
  color: #999999;
}
.status-cards .active {
  background: #99b445;
}
.status-cards .completed {
  background: #00a7ff;
}
.status-cards .scheduled {
  background: #fdb35b;
}
.status-cards .alert {
  background: red;
}
.status-cards .Grants {
  background: #ff5782;
}
.status-cards .onboard {
  background: #9dbcff;
}
.status-cards .cte {
  background: #e88e00;
}
.status-cards .studentService {
  background: #cecb13;
}
