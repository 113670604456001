.notifier {
  background-color: rgb(60, 67, 77);
  position: fixed;
  bottom: 0;
  z-index: 101;
  width: 100%;
}

.notifier > p {
  display: block;
  color: white;
  text-align: center;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}
