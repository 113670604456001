.CourseDetails {
  text-align: center;
}
.CourseDetails .courseTitle {
  font-size: 28px;
  padding: 20px 0px 30px 0px;
}
.CourseDetails .courseInfoContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
.CourseDetails .courseInfoContainer .courseInfoLabel {
  font-size: 18px;
  font-weight: 300;
  text-align: end;
  width: 59%;
}
.CourseDetails .courseInfoContainer .courseInfoData {
  font-size: 18px;
  padding-left: 20px;
  text-align: justify;
  width: 100%;
  white-space: pre-line;
}
.CourseDetails .takeCourseBtn {
  border-radius: 0px;
  margin: 10px 0px;
}
