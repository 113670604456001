/*** -----------------------------------------

              CARD CONTAINER

----------------------------------------- ***/

.cards-container,
.title {
  background: rgb(169, 86, 227);
  background: linear-gradient(
    90deg,
    rgba(169, 86, 227, 1) 0%,
    rgba(101, 98, 255, 1) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  color: #fff;
  display: block;
}
.cards-container .title {
  background: transparent;
}
.title.title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 22px;
  color: #fff;
  font-weight: 400;
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 50px;
  margin: 0;
  text-transform: capitalize;
}
.title > .ant-btn {
  height: 28px;
  padding: 0 25px;
}

/*** -----------------------------------------

                BANNER STATUS

----------------------------------------- ***/

.banner-status {
  font-family: 'Source Sans Pro', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1em 0 0 0;
}
.banner-status > li {
  padding: 10px;
  width: 15%;
  text-align: center;
}
.banner-status > li > span {
  display: block;
  font-size: 40px;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: center;
  line-height: 50px;
  min-height: 50px;
}
