/* .phone-with-ext {
  margin-left: 10px;
} */
.content-pad .phone-with-ext {
  padding-left: 10px;
}
.ant-modal-body .content-pad .phone-with-ext {
  padding-left: 0px;
}
.phone-with-ext
  .ant-input-wrapper.ant-input-group
  > .ant-input-group-addon
  > input {
  width: 50%;
  float: right;
}

.phone-with-ext
  .ant-input-wrapper.ant-input-group
  > .ant-input-group-addon
  > .label {
  position: relative;
  top: 7px;
  width: 50%;
}
.phone-with-ext
  .ant-input-wrapper.ant-input-group
  > .ant-input-group-addon:nth-child(1) {
  background: rgba(0, 0, 0, 0);
  width: 15%;
  padding: 0;
  border-radius: 2px;
}
.phone-with-ext
  .ant-input-wrapper.ant-input-group
  > .ant-input-group-addon:nth-child(3) {
  border: 0;
  background: rgba(0, 0, 0, 0);
  width: 40%;
  padding: 0;
}

.phone-with-ext
  .ant-input-wrapper.ant-input-group
  > .ant-input-group-addon:nth-child(1)
  .ant-select
  .ant-select-selection__rendered {
  /* border-left: 1px solid rgb(220, 220, 220); */
  padding-left: 5px;
  margin: 0;
}

.phone-with-ext .ant-input-group-addon .ant-select .ant-select-selection {
  border: 0px;
}

.phone-with-ext .ant-select-arrow {
  right: 15px;
}

.has-error
  .phone-with-ext
  .ant-input-wrapper.ant-input-group
  > .ant-input-group-addon:nth-child(1)
  .ant-select
  .ant-select-selection__rendered {
  border-color: red;
}
