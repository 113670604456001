.custom-editor {
  position: relative;
}
.custom-editor .counter {
  position: absolute;
  right: 5px;
  bottom: initial;
}
.has-error .custom-editor,
.custom-editor {
  border-color: transparent !important;
}

/* forcefull hide
Note: Need to find some other solution
*/
.tox-browse-url {
  display: none !important;
}
