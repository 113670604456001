.global.dashboard {
  padding: 2em;
}

.global.dashboard > div {
  margin-bottom: 3em;
  border-radius: 0;
  box-shadow: 0 0 10px rgba(189, 189, 189, 0.6);
}

.global.dashboard > div.dashboard-filter {
  box-shadow: none;
  padding: 20px;
  border-radius: 2px;
  background-color: #fafafa;
}

.global.dashboard > div.dashboard-filter > div > div:nth-child(2) {
  padding-left: 10px;
  padding-right: 10px;
}
.global.dashboard > div.dashboard-filter .label {
  color: #393939;
  font-family: 'Source Sans Pro';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 23px;
}
.global.dashboard > div.dashboard-filter .reset {
  color: #00aef0;
  font-family: 'Source Sans Pro';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 22px;
  text-decoration: underline;
  cursor: pointer;
}

.global.dashboard > div.collegeInfo {
  text-align: center;
  box-shadow: none;
}
.global.dashboard > div.collegeInfo .ant-avatar {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  margin-right: 10px;
  border: 1px solid rgb(202, 202, 202);
  padding: 5px;
  background-color: rgba(0, 0, 0, 0);
}
.global.dashboard > div.collegeInfo .ant-avatar > i {
  background: rgb(194, 191, 191);
  border-radius: 100%;
  height: 100%;
  width: 100%;
  font-size: 20px;
  padding-top: 8px;
}

.global.dashboard > div.collegeInfo .ant-avatar > img {
  object-fit: contain;
}
.global.dashboard > div.collegeInfo .collegeName {
  font-size: 18px;
  color: rgb(0, 0, 0);
}
.global.dashboard > div .ant-collapse-item:last-child > .ant-collapse-content,
.global.dashboard > div .ant-collapse > .ant-collapse-item:last-child,
.global.dashboard
  > div
  .ant-collapse
  > .ant-collapse-item:last-child
  > .ant-collapse-header {
  border-radius: 0;
}

.global.dashboard .ant-collapse .ant-collapse-header {
  padding: 15px 20px;
  color: #515151;
  font-weight: 500;
  background-color: rgb(250, 250, 250);
}
.global.dashboard .ant-collapse .ant-collapse-header .action-icons {
  display: inline-block;
  float: right;
  font-size: 25px;
  font-weight: bold;
}

.global.dashboard .ant-collapse .ant-collapse-content {
  border-top: 0px;
  padding: 0px 10px;
}

.global.dashboard .ant-collapse .ant-collapse-content .content .overview-title {
  color: #515151;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
}
.profile .overview-title {
  padding-bottom: 15px;
}
/* Timeline container*/
.global.dashboard .timeLine-container .timeLine-filter > span {
  margin-right: 10px;
  font-weight: 400 !important;
}
.global.dashboard .timeLine-container {
  border: 1px solid rgb(221, 221, 221);
}
.global.dashboard .timeLine-container.no-header {
  border: 0;
}

.global.dashboard .timeLine-container .timeLine-Header {
  padding: 15px 20px;
  color: rgb(81, 81, 81);
  font-weight: 500;
  background-color: rgb(250, 250, 250);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.global.dashboard .timeLine-container .timeLine-Header.no-header {
  background-color: #ffffff;
  flex-direction: row-reverse;
  padding: 0px;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.global.dashboard
  .tabs-container
  .ant-tabs.ant-tabs-card
  > .ant-tabs-bar
  .ant-tabs-ink-bar {
  border-bottom: 2px solid rgb(154, 184, 60);
}
.global.dashboard
  .tabs-container.tabs-container.tabs-container
  .ant-tabs-tab-active.ant-tabs-tab:hover,
.global.dashboard
  .tabs-container.tabs-container.tabs-container
  .ant-tabs-tab-active.ant-tabs-tab {
  color: rgb(154, 184, 60);
}

/*Tabs Styling*/

.global.dashboard .tabs-container {
  margin-top: 20px;
}
.global.dashboard .ant-tabs-bar {
  padding-top: 10px;
  background: rgb(250, 250, 250);
  border-top: 1px solid rgb(217, 220, 223);
  border-left: 1px solid rgb(217, 220, 223);
  border-right: 1px solid rgb(217, 220, 223);
  position: relative;
  z-index: 1;
}
.global.dashboard .ant-tabs-nav-wrap {
  background-color: rgb(250, 250, 250);
  border: 0;
}

.global.dashboard .tabs-container.tabs-container.tabs-container .ant-tabs-tab {
  background-color: rgb(250, 250, 250);
  border: 0px;
  width: auto;
}
.global.dashboard
  .tabs-container.tabs-container.tabs-container
  .ant-tabs-tab:last-child {
  border-right: 0px;
}

/*Progress Bar styles*/
.global.dashboard .progress-container .progress-filters {
  display: flex;
  border-bottom: 1px solid rgb(217, 220, 223);
  padding: 30px 20px 20px 20px;
  flex-direction: row-reverse;
}
.global.dashboard .progress-container .progress-filters .filter {
  margin-left: 10px;
  margin-right: 10px;
}
.global.dashboard .progress-container .progress-filters .filter > div {
  margin-left: 10px;
}
.global.dashboard .progress-list {
  padding: 20px 10px;
  max-height: 240px;
  overflow-y: scroll;
}
.global.dashboard .progress-list .progress-bar {
  margin-top: 20px;
  margin-bottom: 20px;
}
.global.dashboard .progress-list .text-right {
  color: #666666;
  font-size: 16px;
}
.global.dashboard .progress-list .ant-progress-line > div {
  display: flex;
}
.global.dashboard .ant-progress-text {
  font-size: 20px;
  display: none;
}
.global.dashboard .ant-progress-show-info .ant-progress-outer {
  padding: 0;
}

.global.dashboard .no-data-to-display {
  background-color: #f0f0f0;
  text-align: center;
  padding: 20px;
  font-size: 16px;
}

.global.dashboard .ant-collapse > .ant-collapse-item:last-child,
.global.dashboard
  .ant-collapse
  > .ant-collapse-item:last-child
  > .ant-collapse-header {
  border-radius: 0;
}

.student-success-filter {
  text-align: right;
  padding-right: 15px !important;
}
.student-success-filter > .label {
  color: #393939;
  font-family: 'Source Sans Pro';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
  position: relative;
  top: 4px;
}
