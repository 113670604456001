.mt-50 {
  margin-top: 50px;
}

.upload-logo label:before {
  display: inline-block;
  margin-right: 4px;
  content: '*';
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: rgb(245, 34, 45);
}

.tagline .ant-col-offset-1 {
  margin-left: 2%;
}

.tagline .counter {
  position: absolute;
  right: 12px;
  top: 0px;
}

.page-header {
  border: 1px dashed #7e797959;
  position: relative;
  margin: 74px 74px 74px 75px;
}

.profile-header {
  border-bottom: 1px solid #dddddd;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 1em;
  padding-left: 45px;
  margin-bottom: 40px;
  background: #fbfbfb;
  border-top: 1px solid #dddddd;
}
.bp-cprofile-form .counter_no .counter {
  position: absolute;
  top: 0px;
  right: 7px;
}
.bp-cprofile-form .url_web .ant-form-item-control-wrapper input,
.bp-cprofile-form
  .msa_link
  .ant-form-item-control-wrapper
  .ant-select-disabled {
  width: 65%;
}

.ant-form-item.connectedStates-form-item
  .ant-form-item-children
  .ant-row
  .ant-col {
  width: 100%;
}
.ant-col .ant-select {
  height: auto;
}
