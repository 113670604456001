.preWrapText {
  white-space: pre-wrap;
}
.student-profile-header {
  min-height: 250px;
  background-size: cover;
  padding-top: 35px;
  background-repeat: no-repeat;
  background-position-y: bottom;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-left: -24px;
  margin-right: -24px;
  align-items: flex-end;
}

.student-profile-header .actions {
  padding-bottom: 25px;
  margin-right: 30px;
}

.student-profile-header .actions .ant-btn-primary,
.student-profile-header .actions .ant-btn-primary:hover,
.student-profile-header .actions .ant-btn-primary:focus,
.student-profile-header .actions .ant-btn-primary:active {
  font-family: 'Source Sans Pro';
  font-size: 17px;
  color: rgb(255, 255, 255);
  background-color: #39c3fe !important;
  border-color: #39c3fe !important;
}

.student-profile-header .student-details {
  padding: 20px;
  display: flex;
  padding: 30px 30px;
}

.student-profile-header .student-details .rounded-container {
  display: flex;
  padding-right: 20px;
  border-radius: 50px 10px 10px 50px;
  background: rgba(255, 255, 255, 0.9);
}

.student-profile-header .student-details .rounded-container .student-image {
  height: 100px;
  width: 100px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 50%;
  position: relative;
  top: 1px;
}

.student-profile-header
  .student-details
  .rounded-container
  .student-image
  .ant-avatar.ant-avatar-image {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.student-profile-header
  .student-details
  .rounded-container
  .student-image
  .ant-avatar.ant-avatar-circle.ant-avatar-icon {
  font-size: 50px;
  height: 96px;
  width: 100px;
  border-radius: 50%;
  position: relative;
}

.student-profile-header
  .student-details
  .rounded-container
  .student-image
  .ant-avatar.ant-avatar-circle.ant-avatar-icon
  > i {
  position: absolute;
  top: 25%;
  left: 25%;
}

.student-profile-header
  .student-details
  .rounded-container
  .student-image
  .ant-avatar
  > img {
  object-fit: cover;
  background-color: #f0f0f0;
}

.student-profile-header .student-details .rounded-container .details {
  padding-left: 0.75rem;
  color: #fff;
  font-size: 20px;
}

.student-profile-header .student-details .rounded-container .details > span {
  display: block;
  font-size: 14px;
  color: #261d3e;
  margin-bottom: 0px;
}

.student-profile-header
  .student-details
  .rounded-container
  .details
  > span.student-actions {
  display: flex;
  margin-top: 4px;
}

.student-profile-header
  .student-details
  .rounded-container
  .details
  > span.student-actions
  a
  img {
  /* -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7)); */
  filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
}

.student-profile-header
  .student-details
  .rounded-container
  .details
  > .student-actions
  .tiktok-icon,
.student-profile-header
  .student-details
  .rounded-container
  .details
  > .student-actions
  .medium-icon {
  filter: none;
}

.student-profile-header
  .student-details
  .rounded-container
  .details
  > span.student-actions
  > a {
  cursor: pointer;
  margin-right: 10px;
  background: transparent;
  border-color: #d9d9d9;
  border: 0 solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
}

.student-name {
  margin-top: 5px;
  font-size: 20px !important;
  font-family: 'Arial Rounded MT Bold';
}

.profile-status {
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4abaed, #fb55e4);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: double 1px transparent;
  border-radius: 20px;
  display: inline-block;
  width: 100%;
  height: auto;
}

.profile-status > .profile-status-header {
  background-color: #758b93;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  font-family: 'Arial Rounded MT Bold';
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 20px;
  position: relative;
  top: -3px;
}

.profile-status > .profile-status-header > .action > button {
  margin-left: 20px;
  cursor: pointer;
}

.profile-status > .profile-status-header > .action > button.ant-switch {
  background-color: #939393;
}

.profile-status
  > .profile-status-header
  > .action
  > button.ant-switch.ant-switch-checked {
  background-color: #7ec848;
}

.ant-modal.success-modal > .ant-modal-content > .ant-modal-footer > div {
  text-align: center;
}

.ant-modal.success-modal > .ant-modal-content > .ant-modal-header {
  border-bottom: 0px;
}

.skills-modal .SkillLiveSearch > div {
  width: 100%;
}

.skills-modal .SkillLiveSearch .ant-tag {
  border: 1px solid #ffb40a;
  background: white;
  padding: 0px 10px;
  border-radius: 50px;
}

.not-found-container {
  background-color: #f3f3f3;
  padding: 10px;
  text-align: center;
  font-size: 16px;
}

.ProfileContent {
  width: 100%;
}

.ProfileContent > .bio,
.ProfileContent > .educational-pathways,
.ProfileContent > .skills,
.ProfileContent > .intern-apprentice-ships,
.ProfileContent > .work-experience,
.ProfileContent > .profile-myeducation,
.ProfileContent > .profile-volunteerexp,
.ProfileContent > .profile-interests,
.ProfileContent > .profile-portfolio,
.ProfileContent > .profile-skillslibrary {
  margin-top: 20px;
}

.ProfileContent > .bio > .bio-header,
.ProfileContent > .educational-pathways > .educational-pathways-header,
.ProfileContent > .skills > .skills-header,
.ProfileContent > .intern-apprentice-ships > .intern-apprentice-ships-header,
.ProfileContent > .work-experience > .work-experience-header,
.ProfileContent > .profile-myeducation > .myeducation-header,
.ProfileContent > .profile-volunteerexp > .volunteerexp-header,
.ProfileContent > .profile-interests > .interests-header,
.ProfileContent > .profile-portfolio > .portfolio-header,
.ProfileContent > .profile-skillslibrary > .skillslibrary-header {
  background-color: #758b93;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  /* border-radius: 20px; */
  font-family: 'Arial Rounded MT Bold';
}

.ProfileContent > .bio > .bio-content,
.ProfileContent > .educational-pathways > .educational-pathways-content,
.ProfileContent > .skills > .skills-content,
.ProfileContent > .intern-apprentice-ships > .intern-apprentice-ships-content,
.ProfileContent > .work-experience > .work-experience-content,
.ProfileContent > .profile-myeducation > .myeducation-content,
.ProfileContent > .profile-volunteerexp > .volunteerexp-content,
.ProfileContent > .profile-interests > .interests-content,
.ProfileContent > .profile-portfolio > .portfolio-content,
.ProfileContent > .profile-skillslibrary > .skillslibrary-content {
  padding: 10px;
  line-height: 1.4;
  white-space: pre-wrap;
  font-size: 16px;
  color: #333;
}

.ProfileContent > .bio > .content > .sub-header,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .sub-header,
.ProfileContent > .skills > .skills-content > .sub-header,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .sub-header,
.ProfileContent > .work-experience > .work-experience-content > .sub-header {
  font-size: 17px;
  margin-bottom: 15px;
}

.ProfileContent > .bio > .bio-content > .ant-tag,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .ant-tag,
.ProfileContent > .skills > .skills-content > .ant-tag,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .ant-tag,
.ProfileContent > .work-experience > .work-experience-content > .ant-tag {
  border: 1px solid #ffb40a;
  background: white;
  padding: 4px 15px;
  border-radius: 50px;
  margin-bottom: 5px;
  height: 30px;
}

.ProfileContent .educational-pathways-content h2 {
  font-size: 17px;
  font-weight: bold;
}

.ProfileContent > .bio > .content > .work-experience-item,
.ProfileContent > .educational-pathways > .content > .work-experience-item,
.ProfileContent > .skills > .content > .work-experience-item,
.ProfileContent > .intern-apprentice-ships > .content > .work-experience-item,
.ProfileContent > .work-experience > .content > .work-experience-item {
  font-size: 16px;
  max-width: 50%;
}

.ProfileContent
  > .bio
  > .bio-content
  > .work-experience-item:last-child
  ._divider,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .work-experience-item:last-child
  ._divider,
.ProfileContent
  > .skills
  > .skills-content
  > .work-experience-item:last-child
  ._divider,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item:last-child
  ._divider,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item:last-child
  ._divider {
  display: none;
}

.ProfileContent > .bio-content > .work-experience-item > div,
.ProfileContent
  > .educational-pathways
  > .content
  > .work-experience-item
  > div,
.ProfileContent > .skills > .skills-content > .work-experience-item > div,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item
  > div,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item
  > div {
  margin: 10px 0px;
}

.ProfileContent
  > .bio
  > .bio-content
  > .work-experience-item
  > .time-period
  > span.end,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .work-experience-item
  > .time-period
  > span.end,
.ProfileContent
  > .skills
  > .skills-content
  > .work-experience-item
  > .time-period
  > span.end,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item
  > .time-period
  > span.end,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item
  > .time-period
  > span.end {
  margin-left: 5px;
}

.ProfileContent
  > .bio
  > .bio-content
  > .work-experience-item
  > .header-details
  > .company-name,
.ProfileContent
  > .bio
  > .bio-content
  > .work-experience-item
  > .role-n-responsibilities
  > .title,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .work-experience-item
  > .header-details
  > .company-name,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .work-experience-item
  > .role-n-responsibilities
  > .role-n-responsibilities-title,
.ProfileContent
  > .skills
  > .skills-content
  > .work-experience-item
  > .header-details
  > .company-name,
.ProfileContent
  > .skills
  > .skills-content
  > .work-experience-item
  > .role-n-responsibilities
  > .role-n-responsibilities-title,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item
  > .header-details
  > .company-name,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item
  > .role-n-responsibilities
  > .role-n-responsibilities-title,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item
  > .header-details
  > .company-name,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item
  > .role-n-responsibilities
  > .role-n-responsibilities-title {
  font-weight: bold;
}

.ProfileContent
  > .bio
  > .bio-content
  > .work-experience-item
  > .header-details
  > .action-buttons,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .work-experience-item
  > .header-details
  > .action-buttons,
.ProfileContent
  > .skills
  > .skills-content
  > .work-experience-item
  > .header-details
  > .action-buttons,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item
  > .header-details
  > .action-buttons,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item
  > .header-details
  > .action-buttons {
  float: right;
  font-size: 13px;
}

.ProfileContent
  > .bio
  > .bio-content
  > .work-experience-item
  > .header-details
  > .action-buttons
  > .anticon,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .work-experience-item
  > .header-details
  > .action-buttons
  > .anticon,
.ProfileContent
  > .skills
  > .skills-content
  > .work-experience-item
  > .header-details
  > .action-buttons
  > .anticon,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item
  > .header-details
  > .action-buttons
  > .anticon,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item
  > .header-details
  > .action-buttons
  > .anticon {
  margin-left: 10px;
  cursor: pointer;
}

.ProfileContent
  > .bio
  > .bio-content
  > .work-experience-item
  > .header-details
  > .action-buttons
  > .anticon:hover,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .work-experience-item
  > .header-details
  > .action-buttons
  > .anticon:hover,
.ProfileContent
  > .skills
  > .skills-content
  > .work-experience-item
  > .header-details
  > .action-buttons
  > .anticon:hover,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item
  > .header-details
  > .action-buttons
  > .anticon:hover,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item
  > .header-details
  > .action-buttons
  > .anticon:hover {
  color: #ffb40a;
}

.ProfileContent
  > .bio
  > .bio-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities,
.ProfileContent
  > .skills
  > .skills-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities {
  padding: 5px 10px;
}

.ProfileContent
  > .bio
  > .bio-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  ul,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  ul,
.ProfileContent
  > .skills
  > .skills-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  ul,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  ul,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  ul {
  margin: 0px;
  padding-left: 18px;
}

.ProfileContent
  > .bio
  > .bio-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  > .point,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  > .point,
.ProfileContent
  > .skills
  > .skills-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  > .point,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  > .point,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  > .point {
  display: flex;
}

.ProfileContent .point > i {
  margin-top: 4px;
}

.ProfileContent
  > .bio
  > .bio-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  > .point
  > .anticon,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  > .point
  > .anticon,
.ProfileContent
  > .skills
  > .skills-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  > .point
  > .anticon,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  > .point
  > .anticon,
.ProfileContent
  > .work-experience
  > .work-experience-content
  > .work-experience-item
  > .role-n-responsibilities
  > .responsibilities
  > .point
  > .anticon {
  margin-right: 5px;
}

.ProfileContent>.bio>.bio-content ._section-content,
/* .ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  ._section-content, */
.ProfileContent>.skills>.skills-content ._section-content,
.ProfileContent>.intern-apprentice-ships>.intern-apprentice-ships-content ._section-content,
.ProfileContent>.work-experience>.work-experience-content ._section-content {
  height: 100%;
  margin-bottom: 30px;
}

.ProfileContent > .bio > .bio-content ._section-content ._card,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  ._section-content
  ._card,
.ProfileContent > .skills > .skills-content ._section-content ._card,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  ._section-content
  ._card,
.ProfileContent
  > .work-experience
  > .work-experience-content
  ._section-content
  ._card {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 13px 3px #d9d9d991, 0px 0px 0px 1px #d9d9d961;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  transition: 0.3s all;
  min-height: 245px;
  height: 245px;
}

.ProfileContent
  > .bio
  > .content
  ._section-content
  ._card
  > ._studygroup
  > ._content,
.ProfileContent
  > .educational-pathways
  > .content
  ._section-content
  ._card
  > ._studygroup
  > ._content,
.ProfileContent
  > .skills
  > .content
  ._section-content
  ._card
  > ._studygroup
  > ._content,
.ProfileContent
  > .intern-apprentice-ships
  > .content
  ._section-content
  ._card
  > ._studygroup
  > ._content,
.ProfileContent
  > .work-experience
  > .content
  ._section-content
  ._card
  > ._studygroup
  > ._content {
  font-size: 17px;
  font-weight: 300;
}

.ProfileContent
  > .bio
  > .content
  ._section-content
  ._card
  > ._studygroup
  > ._content
  > ._title,
.ProfileContent
  > .educational-pathways
  > .content
  ._section-content
  ._card
  > ._studygroup
  > ._content
  > ._title,
.ProfileContent
  > .skills
  > .content
  ._section-content
  ._card
  > ._studygroup
  > ._content
  > ._title,
.ProfileContent
  > .intern-apprentice-ships
  > .content
  ._section-content
  ._card
  > ._studygroup
  > ._content
  > ._title,
.ProfileContent
  > .work-experience
  > .content
  ._section-content
  ._card
  > ._studygroup
  > ._content
  > ._title {
  font-weight: 600;
}

.ProfileContent > .bio > .content ._section-content ._card._remove-shadow,
.ProfileContent
  > .educational-pathways
  > .content
  ._section-content
  ._card._remove-shadow,
.ProfileContent > .skills > .content ._section-content ._card._remove-shadow,
.ProfileContent
  > .intern-apprentice-ships
  > .content
  ._section-content
  ._card._remove-shadow,
.ProfileContent
  > .work-experience
  > .content
  ._section-content
  ._card._remove-shadow {
  box-shadow: none;
}

.ProfileContent
  > .bio
  > .content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div,
.ProfileContent
  > .educational-pathways
  > .content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div,
.ProfileContent
  > .skills
  > .content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div,
.ProfileContent
  > .intern-apprentice-ships
  > .content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div,
.ProfileContent
  > .work-experience
  > .work-experience-content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div {
  padding: 10px;
}

.ProfileContent
  > .bio
  > .bio-content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div.college-info,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div.college-info,
.ProfileContent
  > .skills
  > .skills-content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div.college-info,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div.college-info,
.ProfileContent
  > .work-experience
  > .work-experience-content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div.college-info {
  border-top: 1px solid #ddd;
}

.ProfileContent
  > .bio
  > .bio-content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div.college-info
  > .ant-avatar.ant-avatar-icon,
.ProfileContent
  > .educational-pathways
  > .educational-pathways-content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div.college-info
  > .ant-avatar.ant-avatar-icon,
.ProfileContent
  > .skills
  > .skills-content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div.college-info
  > .ant-avatar.ant-avatar-icon,
.ProfileContent
  > .intern-apprentice-ships
  > .intern-apprentice-ships-content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div.college-info
  > .ant-avatar.ant-avatar-icon,
.ProfileContent
  > .work-experience
  > .work-experience-content
  ._section-content
  ._card._remove-shadow
  > ._studygroup
  > ._content
  > div.college-info
  > .ant-avatar.ant-avatar-icon {
  border-radius: 50%;
  border: 1px solid orange;
  margin: 3px;
  height: 45px;
  width: 45px;
  padding: 5px;
}

.ProfileContent > .bio > .action,
.ProfileContent > .educational-pathways > .action,
.ProfileContent > .skills > .action,
.ProfileContent > .intern-apprentice-ships > .action,
.ProfileContent > .work-experience > .action {
  padding-top: 10px;
}

.ProfileContent > ._toolbar {
  width: 100%;
  border: 1px solid #bbbbbb;
  padding: 13px;
}

.ProfileContent > ._toolbar .row {
  width: 100%;
}

.ProfileContent > ._toolbar .row ._ttl {
  font-size: 22px;
  color: #444444;
}

.ProfileContent
  > ._toolbar
  .row
  ._filter-checkbox
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #ffb40a;
  border-color: #ffb40a;
}

.ProfileContent > ._toolbar .row ._filter-checkbox .ant-checkbox-checked:after {
  border-color: #ffb40a;
}

.ProfileContent > ._toolbar .row ._filter-checkbox .ant-checkbox-inner {
  width: 24px;
  height: 24px;
}

.ProfileContent > ._toolbar .row ._filter-checkbox .ant-checkbox-inner:after {
  top: 5.14px;
  left: 8.5px;
}

.ProfileContent > ._toolbar .row ._filter-checkbox > span {
  color: #444444;
  font-size: 15px;
  font-weight: 500;
  text-shadow: 0px 0px 0px #00000056;
}

.ProfileContent > ._list-details {
  margin: 25px 0px;
}

.ProfileContent > ._list-details > ._ttl {
  font-size: 21px;
  color: #333333;
}

.ProfileContent > ._list-details > ._details-content {
  margin-top: 11px;
}

.ProfileContent > ._list-details > ._details-content > .textarea-bio {
  border: none;
  pointer-events: none;
  font-size: 16px;
  color: #666666;
  white-space: pre-wrap;
}

.ProfileContent > ._list-details > ._details-content > .textarea-bio-less {
  max-height: 96px;
  border: none;
  pointer-events: none;
  font-size: 16px;
  color: #666666;
  overflow: hidden;
  white-space: pre-wrap;
}

.ProfileContent > ._list-details > ._details-content > label {
  color: #e39d08;
  display: block;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  width: 70px;
}

.ProfileContent > ._list-details > ._details-content span {
  color: #e39d08;
  display: block;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.ProfileContent ._docs-list > ._item {
  display: flex;
  align-items: center;
}

.ProfileContent ._docs-list > ._item > ._ic {
  margin-right: 9px;
  font-size: 31px;
}

.ProfileContent ._docs-list > ._item > .portfolioUrlLink {
  margin-right: 9px;
  text-decoration: underline;
}

.ProfileContent ._docs-list > ._item > .download-resume {
  color: #444444;
}

.ProfileContent ._docs-list > ._item > .download-resume:hover {
  color: blue;
}

.ProfileContent ._docs-list > ._item > .del-ic {
  margin-left: 21px;
  font-size: 16px;
  cursor: pointer;
}

.ProfileContent ._docs-list > ._item > .doc-name {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.ProfileContent > ._divider {
  height: 16px;
  background-color: transparent;
  border-bottom: 1px solid #dddddd;
}

.profile-status > .profile-options {
  padding: 20px;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
}

.profile-status
  > .profile-options
  .ant-checkbox-wrapper-checked:hover
  > .ant-checkbox::after {
  border-color: #de4279;
}

.profile-status
  > .profile-options
  .ant-checkbox-wrapper
  > .ant-checkbox.ant-checkbox-checked
  > .ant-checkbox-inner {
  background-color: #de4279;
  border-color: #de4279;
}

.profile-status > .profile-options .ant-checkbox-wrapper .ant-checkbox + span {
  color: #3a3a3a;
  font-family: 'Arial Rounded MT Bold';
}

/* Overview Panel */
.OverviewPanel {
  width: 100%;
  max-width: 300px;
  padding: 27px;
  box-shadow: 0px 0px 13px 3px #d9d9d991, 0px 0px 0px 1px #d9d9d961;
}

.OverviewPanel > ._head {
  font-size: 21px;
  color: #333333;
}

.OverviewPanel > ._section {
  margin-top: 17px;
}

.OverviewPanel > ._section > ._section-title {
  font-size: 16px;
  color: #444444;
}

.OverviewPanel > ._section > ._list {
  margin-top: 5px;
}

.OverviewPanel > ._section > ._list > ._item > div {
  display: inline-block;
}

.OverviewPanel > ._section > ._list > ._item > ._k {
  color: #666666;
  margin-right: 3px;
  font-weight: 300;
}

.OverviewPanel > ._section > ._list > ._item > ._v {
  color: #444444;
  font-weight: 300;
}

.OverviewPanel > ._section > ._list > ._item > .value {
  font-weight: 300;
  color: #ffb40a;
}

/* side panel */
.Profile-MyProfile {
  /* padding: 60px 40px; */
  background-color: #fff;
}

.Profile-MyProfile .side-panel .ant-tag {
  border: none;
  padding: 4px 15px;
  border-radius: 50px;
  margin-bottom: 5px;
  height: 30px;
  font-weight: bold;
}

.Profile-MyProfile .sub-panel-title {
  font-size: 17px;
  font-weight: bold;
  color: #261d3e;
}

.Profile-MyProfile .sub-panel-value {
  font-size: 15px;
  padding-bottom: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Profile-MyProfile ._list ._item ._k {
  font-size: 14px;
  font-weight: bold;
  color: #261d3e;
}

.Profile-MyProfile ._list ._item {
  padding-bottom: 10px;
}

.Profile-MyProfile ._list ._item ._k {
  font-size: 14px;
}

.list_blueDot {
  padding: 0px;
  padding-left: 18px;
}

.list_blueDot li {
  color: #333333;
  list-style-image: url('.././../../sharedResources/assets/images/bluedot.svg');
  padding-bottom: 8px;
  padding-left: 0px;
  background: transparent;
  line-height: 1.4;
}

.Profile-MyProfile .side-panel {
  width: 100%;
  max-width: 300px;
  padding: 15px;
  box-shadow: 0px 0px 13px 3px #d9d9d991, 0px 0px 0px 1px #d9d9d961;
  border-radius: 15px;
}

.Profile-MyProfile .side-panel > .panel-title {
  font-size: 21px;
  color: #de4279;
  margin-bottom: 9px;
}

.Profile-MyProfile .side-panel > .panel-section > .section-title {
  font-size: 17px;
  color: #4f4a46;
  font-weight: 600;
}

.Profile-MyProfile .side-panel > .panel-section > .pathcard {
  width: 80%;
}

.Profile-MyProfile .side-panel > .panel-section > .pathcard .pathcard_sec {
  width: auto !important;
  list-style: none;
  display: inline-block;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(38 29 62 / 14%);
  border-radius: 30px;
  margin: 10px 0px;
  vertical-align: top;
  position: relative;
  font-family: 'Arial Rounded MT Bold';
  cursor: pointer;
}

.Profile-MyProfile .side-panel > .panel-section > .pathcard .pathcard_sec img {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  width: 100%;
  height: 120px;
}

.Profile-MyProfile
  .side-panel
  > .panel-section
  > .pathcard
  .pathcard_sec
  .avatar {
  position: absolute;
  top: 110px;
  left: 10px;
}

.Profile-MyProfile
  .side-panel
  > .panel-section
  > .pathcard
  .pathcard_sec
  .avatar
  .ant-avatar {
  box-shadow: 1px 2px 3px #ddd;
}

.Profile-MyProfile
  .side-panel
  > .panel-section
  > .pathcard
  .pathcard_sec
  .avatar
  img {
  width: 100%;
  height: auto;
  background: #fff;
}

.Profile-MyProfile
  .side-panel
  > .panel-section
  > .pathcard
  .pathcard_sec
  .px-3 {
  padding: 14px 14px 2px;
}

.Profile-MyProfile
  .side-panel
  > .panel-section
  > .pathcard
  .pathcard_sec
  .px-3
  h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 168px;
  margin: 0px;
}

.Profile-MyProfile
  .side-panel
  > .panel-section
  > .pathcard
  .pathcard_sec
  .px-3
  h5 {
  color: #8050f4;
  height: 40px;
  margin: 4px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.Profile-MyProfile
  .side-panel
  > .panel-section
  > .pathcard
  .pathcard_sec
  .px-3
  p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #a0a4b5;
  font-size: 14px;
  width: 100%;
}

.Profile-MyProfile
  .side-panel
  > .panel-section
  > .pathcard
  .pathcard_sec
  .px-3
  p
  span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 160px;
  display: block;
}

.Profile-MyProfile
  .side-panel
  > .panel-section
  > .pathcard
  .pathcard_sec
  h5
  img {
  top: 0px;
  width: auto;
  height: auto;
  margin-right: 6px;
}

.Profile-MyProfile .side-panel > .panel-section > .plain-body {
  margin: 11px 0px;
}

.Profile-MyProfile .side-panel > .panel-section > .plain-body > .long-tag,
.Profile-MyProfile .ProfileContent .profile-interests .long-tag {
  white-space: break-spaces;
  display: inline-table;
  background: #e6e7ff;
  color: #8050f4;
  font-size: 14px;
}

.Profile-MyProfile .side-panel > .panel-section > ._list {
  margin: 11px 0px;
  padding-right: 17px;
  position: relative;
}

.Profile-MyProfile .side-panel > .panel-section > ._list > ._item ._k {
  color: #222;
  margin-right: 4px;
}

.Profile-MyProfile .side-panel > .panel-section > ._list > ._item ._v {
  font-weight: 300;
  font-size: 14px;
  color: #4f4a46;
}

.Profile-MyProfile .side-panel > .panel-section > ._list > ._item.as-list {
  padding: 0px;
}

.Profile-MyProfile .side-panel > .panel-section > ._list > .list-actions {
  position: absolute;
  right: -12px;
  display: flex;
  align-items: center;
}

.Profile-MyProfile .side-panel > .panel-section > ._list > .list-actions > i {
  margin-left: 5px;
  cursor: pointer;
}

.Profile-MyProfile
  .side-panel
  > .panel-section
  > ._list
  > .list-actions
  > i:hover {
  color: #ffb40a;
}

.Profile-MyProfile .side-panel > .panel-section > .btn-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
}

.Profile-MyProfile .side-panel > .panel-section-divider {
  margin-left: -17px;
  width: calc(100% + 33px);
  /* margin-right: -27px; */
}

/* skill panel */
.SkillsPanel {
  width: 100%;
  max-width: 300px;
  box-shadow: 0px 0px 13px 3px #d9d9d991, 0px 0px 0px 1px #d9d9d961;
}

.SkillsPanel > ._head {
  font-size: 19px;
  padding: 27px;
  color: #de4279;
}

.SkillsPanel > ._section {
  width: 100%;
}

.SkillsPanel > ._section > ._chart {
  width: 100%;
}

.SkillsPanel > ._section > ._chart svg {
  overflow: overlay !important;
}

.SkillsPanel > ._section > ._chart svg .caption.title {
  display: none;
}

.SkillsPanel > ._section > .tags {
  text-align: center;
  padding-bottom: 36px;
}

.SkillsPanel > ._section > .tags > .button {
  color: #ffb40a;
  font-size: 16px;
  border: 1px solid #ffb40a;
}

.location-title {
  font-size: 16px;
  color: #4f4a46;
  font-weight: 600;
}

.educational-pathways
  > .educational-pathways-content
  > ._section-content
  .ant-tag {
  color: #8050f4;
  background: #e6e7ff;
  border: none;
  font-weight: bold;
  border-radius: 50px;
  padding: 3px 20px;
  margin: 0px 5px 10px 0px;
  height: 27px;
  font-size: 14px;
  text-shadow: none;
  box-shadow: none;
}

.srm.student.management.profile
  .ProfileContent.Profile-MyProfile
  > .ant-row-flex
  > div,
.school.student.management.profile
  .ProfileContent.Profile-MyProfile
  > .ant-row-flex
  > div,
.institution.student.management.profile
  .ProfileContent.Profile-MyProfile
  > .ant-row-flex
  > div {
  padding-left: 0 !important;
}

.srm.student.management.profile
  .ProfileContent.Profile-MyProfile
  > .ant-row-flex
  > div:nth-child(2),
.school.student.management.profile
  .ProfileContent.Profile-MyProfile
  > .ant-row-flex
  > div:nth-child(2),
.institution.student.management.profile
  .ProfileContent.Profile-MyProfile
  > .ant-row-flex
  > div:nth-child(2) {
  padding-right: 0 !important;
}

.Profile-MyProfile .ant-divider-horizontal {
  margin: 15px 0;
}

.ProfileContent .infocard._item._card {
  border-radius: 20px;
  width: 94% !important;
}

.ProfileContent .infocard._item._card ._bgimage {
  border-radius: 20px 20px 0px 0px;
}

.ProfileContent .infocard._item._card ._content {
  width: 100%;
  margin: 0px;
}

.ProfileContent .infocard._item._card ._content ._title {
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  margin: 0px;
}

.ProfileContent .infocard._item._card ._collegeName {
  color: #a0a4b5;
  font-size: 14px;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ProfileContent .infocard._item._card ._awardType {
  color: #8050f4;
  height: 40px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}

.ProfileContent .skills .skills-content .ant-tag {
  color: #8050f4;
  background: #e6e7ff;
  border: none;
  font-weight: bold;
  font-size: 14px;
}

.ProfileContent .work-experience .responsibilities li {
  color: #333333;
  list-style-image: url('.././../../sharedResources/assets/images/bluedot.svg');
  font-size: 16px;
  padding-bottom: 8px;
  padding-left: 0px;
  background: transparent;
  line-height: 1.4;
}

.ProfileContent .work-experience-content ._item a {
  color: #1890ff;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.ProfileContent .work-experience-item .role-n-responsibilities {
  margin: 0px !important;
}

.ProfileContent .work-experience-item .header-details {
  font-size: 16px;
  font-weight: bold;
}

.ProfileContent .panel-section .no-results-msg {
  background: transparent;
}

.ProfileContent
  .no-results-text.no-results-text.no-results-text.no-results-text {
  padding: 0px;
  margin: 0px;
  background: transparent;
}

.ProfileContent
  .side-panel
  .no-results-text.no-results-text.no-results-text.no-results-text {
  text-align: left;
}

.ProfileContent .bio .no-results-msg.no-results-text {
  padding-top: 10px !important;
}

.ProfileContent .educational-pathways .educational-pathways-content .pathcard {
  width: auto;
}

.ProfileContent
  .educational-pathways
  .educational-pathways-content
  .pathcard
  .pathcard_sec {
  width: auto !important;
  list-style: none;
  display: inline-block;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(38 29 62 / 14%);
  border-radius: 30px;
  margin: 0px 10px 20px;
  vertical-align: top;
  position: relative;
  font-family: 'Arial Rounded MT Bold';
  cursor: pointer;
}

.ProfileContent
  .educational-pathways
  .educational-pathways-content
  .pathcard
  .pathcard_sec
  img {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  width: 100%;
  height: 133px;
}

.ProfileContent
  .educational-pathways
  .educational-pathways-content
  .pathcard
  .pathcard_sec
  .px-3 {
  padding: 28px 15px 12px;
}

.ProfileContent
  .educational-pathways
  .educational-pathways-content
  .pathcard
  .pathcard_sec
  .avatar
  .ant-avatar {
  box-shadow: 1px 2px 3px #ddd;
}

.ProfileContent
  .educational-pathways
  .educational-pathways-content
  .pathcard
  .pathcard_sec
  .avatar {
  position: absolute;
  top: 110px;
  left: 10px;
}

.ProfileContent
  .educational-pathways
  .educational-pathways-content
  .pathcard
  .pathcard_sec
  .px-3
  h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 168px;
  margin: 0px;
}

.ProfileContent
  .educational-pathways
  .educational-pathways-content
  .pathcard
  .pathcard_sec
  .px-3
  p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #a0a4b5;
  font-size: 14px;
  width: 100%;
}

.ProfileContent
  .educational-pathways
  .educational-pathways-content
  .pathcard
  .pathcard_sec
  .px-3
  p
  span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 160px;
  display: block;
}

.ProfileContent
  .educational-pathways
  .educational-pathways-content
  .pathcard
  .pathcard_sec
  .px-3
  h5 {
  color: #8050f4;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.ProfileContent
  .educational-pathways
  .educational-pathways-content
  .pathcard
  .pathcard_sec
  h5
  img {
  width: auto;
  height: auto;
  top: 0px;
}

.educational-pathways
  .educational-pathways-content
  .pathcard
  .pathcard_sec
  .avatar
  img {
  width: 100%;
  height: auto;
  background: #fff;
}

.Profile-MyProfile .ProfileContent .profileUserInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 0 15px;
}

.Profile-MyProfile .ProfileContent .profile-overviewinfo {
  display: flex;
}

.Profile-MyProfile .ProfileContent .profile-overviewinfo .sub-panel-value {
  font-size: 17px;
  padding-bottom: 0;
  margin-left: 5px;
}

.Profile-MyProfile .ProfileContent .profile-myeducation ._item ._k {
  width: 150px;
  font-family: 'Arial Rounded MT Bold';
  margin-right: 5px;
  display: inline-block;
  text-align: right;
}

.Profile-MyProfile
  .ProfileContent
  .profile-myeducation
  .myeducation-content
  ._list
  ._item
  .award_v {
  text-transform: capitalize;
}

.Profile-MyProfile .ProfileContent .profile-myeducation ._item .list_blueDot {
  margin-left: 150px;
}

.Profile-MyProfile .ProfileContent .profile-volunteerexp .list_blueDot {
  padding-left: 30px;
}

.Profile-MyProfile .ProfileContent .profile-interests .ant-tag {
  border: none;
  padding: 4px 15px;
  border-radius: 50px;
  margin-bottom: 5px;
  height: 30px;
  font-weight: bold;
}

.Profile-MyProfile .ProfileContent .profile-portfolio .portfolioUrlSec {
  display: flex;
  font-size: 16px;
  align-items: center;
}

.Profile-MyProfile
  .ProfileContent
  .profile-portfolio
  .portfolioUrlSec
  .portfolioUrlBtn {
  color: #1890ff;
  /* font-size: 16px; */
  text-transform: lowercase;
  text-decoration: underline;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential-container {
  border-bottom: 1px solid #cccccc;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential-container
  .credential-content {
  max-height: 375px;
  overflow-y: auto;
}

.Profile-MyProfile .ProfileContent .profile-skillslibrary .credential-header {
  font-family: 'Arial Rounded MT Bold';
  font-size: 15px;
  color: #000000;
  margin: 5px 0px;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .credential-date-container {
  padding: 15px;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .credential-date-container
  p {
  /* font-family: Nunito Regular; */
  font-size: 15px;
  color: #000000;
  margin: 0px;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .credential-date-container
  p
  .purple-text {
  color: #8050f4;
  font-family: 'Arial Rounded MT Bold';
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .credential-date-container
  .credential_link {
  padding-left: 20px;
  text-decoration: underline;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .credential-card {
  width: 80%;
  margin-bottom: 25px;
  margin-left: 10px;
  font-family: 'Arial Rounded MT Bold';
  box-shadow: 0px 0px 4px rgb(38 29 62 / 14%);
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .credential-img {
  width: 100%;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .credential-card-details {
  padding: 10px;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .credential-card-details
  h4 {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .credential-card-details
  p {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .credential-card-details
  h5 {
  font-size: 12px;
  color: #8050f4;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .skills-section
  h2 {
  font-size: 16px;
  padding-bottom: 12px;
  font-family: 'Arial Rounded MT Bold';
  border-bottom: 1px solid #cccccc;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .skills-section
  .skills-container {
  margin: 0px;
  max-height: 256px;
  overflow-y: auto;
  padding: 12px 0px;
}

.Profile-MyProfile
  .ProfileContent
  .profile-skillslibrary
  .credential
  .skills-section
  .skills-container
  li {
  list-style-type: none;
  display: inline-block;
  border-radius: 30px;
  background: #e6e7ff;
  color: #8050f4;
  margin: 0px 6px 18px;
  padding: 6px 0px;
  font-size: 12px;
  font-family: 'Arial Rounded MT Bold';
}

.skills-section ul.skills-container {
  max-height: 130px;
  overflow: auto;
  padding-left: 0px;
  min-height: auto;
}

.skills-section .skills-container li {
  list-style-type: none;
  display: inline-block;
  border-radius: 30px;
  background: #e6e7ff;
  color: #8050f4;
  /* margin: 30px 0px 6px 18px; */
  margin: 0px 10px 15px 0px;
  padding: 6px 24px;
  font-size: 12px;
  font-family: 'Arial Rounded MT Bold';
}

.Profile-MyProfile .ProfileContent .downloadContainer .profile-download-btn {
  color: red;
  border: 1px solid red;
  border-radius: 0;
  background-color: white;
}

.student-profile-header .profile-qr-code {
  padding-right: 33px;
  border-radius: 50px 0px 0px 50px;
  align-self: end;
  margin-bottom: 35%;
  text-align: right;
}

.student-profile-header .profile-qr-code .profile-qr-code-img {
  height: 50px;
  width: 50px;
  transition: all 0.5s ease-in-out;
}

.student-profile-header .profile-qr-code .profile-qr-code-img:hover {
  z-index: 4;
  height: 80px;
  width: 80px;
  object-fit: contain;
  transform: scale3d(1, 1.1, 1.1) translate3d(-15%, 0%, 0) perspective(300px);
}

@media only screen and (min-width: 1390px) and (max-width: 2500px) {
  .educational-pathways-content ._section-content .ant-col-xl-8 {
    width: 28% !important;
  }

  .educational-pathways-content ._section-content .ant-col-xl-16 {
    width: 72% !important;
  }
}
